import styled from 'styled-components';
import carbonVariables from '../../utils/CarbonVars';

interface Props {
    color: string;
    size: string;
    weight: string;
}

export const Text = styled('div')<Props>`
    ${({ color }) => color === color && `color: ${carbonVariables[color]}; `}
    ${({ size }) => size === size && `font-size: ${carbonVariables[size]}; `}
    ${({ weight }) => weight === weight && `font-weight: ${carbonVariables[weight]}; `}
`;
