import { Column, Form, Grid, Row } from 'carbon-components-react';
import { KcContextBase } from 'keycloakify';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Button } from '../../Component/Button/Button';
import { ButtonLinkGtm } from '../../Component/ButtonLinkGtm/ButtonLinkGtm';
import { Checkbox } from '../../Component/Checkbox/Checkbox';
import { ContentHeader } from '../../Component/ContentHeader/ContentHeader';
import { NewHeader } from '../../Component/NewHeader/NewHeader';
import Input from '../../Component/Input/Input';
import { TermsOfUseModal } from '../../Component/TermsOfUseModal/TermsOfUseModal';
import { useRegisterPage } from '../../hook/useRegisterPage';
import { useSEO } from '../../hook/useSEO';
import { errorRegister, receiveError } from '../../utils/receiveError';
import { isValidCPF } from '../../utils/validarCpf';
import './LoginUpdateProfile.scss';
import { NewFooter } from '../../Component/NewFooter/NewFooter';

const FIELDS_NAME = {
    email: 'email',
    password: 'password',
    cpf: 'CPF',
};

type formData = {
    username?: string | undefined;
    email?: string | undefined;
    firstName?: string | undefined;
    lastName?: string | undefined;
    user?: any;
};

function LoginUpdateProfile(props: KcContextBase.LoginUpdateProfile) {
    let DefaultFormFields = {
        username: '',
        email: '',
        firstName: '',
        lastName: '',
        user: { attributes: { cellphone: '' } },
    };
    const [social, setSocial] = useState('');
    const [errorRegister, setErrorRegister] = useState<errorRegister | undefined>(undefined);
    if (props.user)
        DefaultFormFields = {
            ...DefaultFormFields,
            email: props.user.email || '',
            firstName: props.user.firstName || '',
        };

    const [formFields, setFormFields] = useState<formData>(DefaultFormFields);
    const [username, setUsername] = useState(DefaultFormFields.username);
    const [cellphone, setCellphone] = useState(DefaultFormFields.user.attributes.cellphone);
    const [firstName, setFirstName] = useState(DefaultFormFields.firstName);
    const [lastName, setLastName] = useState(DefaultFormFields.lastName);
    const [metaTags, setMetaTags] = useState<any>({});
    const [description, setDescription] = useState('');
    const [conditionOne, setConditionOne] = useState('');
    const [conditionTwo, setConditionTwo] = useState('');
    const [termsUse, setTermsUse] = useState('');

    const getSeoHook = useSEO('pageRegistration');
    const dataCMS = useRegisterPage();

    useEffect(() => {
        const { data, loading } = dataCMS.getRegister();

        if (!loading) {
            setMetaTags(data?.pageRegistration?.seo);
            setDescription(data.pageRegistration.instruction);
            setConditionOne(data.pageRegistration.condition_one);
            setConditionTwo(data.pageRegistration.condition_two);
            setTermsUse(data.pageRegistration.terms_of_use);
        }
    }, [dataCMS]);

    useEffect(() => {
        const { data, loading } = getSeoHook.getSEO();
        if (!loading) {
            setMetaTags(data?.pageRegistration?.seo);
        }
    }, [getSeoHook]);

    useEffect(() => {
        if (props.message?.summary) {
            const errors = receiveError(props.message?.summary);
            setErrorRegister(errors);
        }
        if (props.user) {
            setFormFields(props.user);

            const firstName = props?.user?.firstName ? props?.user?.firstName.split(' ')[0] : '';
            const lastName = props?.user?.firstName
                ? props?.user?.firstName.split(' ').slice(1).join(' ')
                : '';

            setFirstName(firstName);
            setLastName(lastName);
        }

        if (props.pageId) {
            setSocial('social');
        }
    }, []);

    const handleChange = (event: { target: { name: any; value: any; label: any } }) => {
        const { name, value, label } = event.target;
        setFormFields({ ...formFields, [name]: value });

        if (errorRegister?.errorEmail && name === FIELDS_NAME.email) {
            setErrorRegister({ ...errorRegister, errorEmail: false });
        }
        if (errorRegister?.errorPassword && name === FIELDS_NAME.password) {
            setErrorRegister({ ...errorRegister, errorPassword: false });
        }
        if (errorRegister?.errorCPF && label === FIELDS_NAME.cpf) {
            setErrorRegister({ ...errorRegister, errorCPF: false });
        }
        if (label === 'CPF') {
            setFormFields({ ...formFields, username: value });
        } else if (label === 'Telefone') {
            setFormFields({ ...formFields, user: { attributes: { cellphone: value } } });
        }
    };

    const handleChangeCPF = (event: { target: { name: any; value: any; label: any } }) => {
        const { value } = event.target;
        setUsername(value);
        const newCpf = value
            .replace(/\D/g, '')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})/, '$1-$2')
            .replace(/(-d{2})\d+?$/, '$1');
        setFormFields({ ...formFields, username: newCpf });

        setErrorRegister({ ...errorRegister, errorCPF: !isValidCPF(value) });
    };

    const handleChangeCellphone = (event: { target: { name: any; value: any; label: any } }) => {
        const { value } = event.target;
        setCellphone(value);
        setFormFields({ ...formFields, user: { attributes: { cellphone: value } } });
    };

    const maskCPF = (cpf: string | undefined) => {
        return (
            cpf &&
            cpf
                .replace(/\D/g, '')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d{1,2})/, '$1-$2')
                .replace(/(-d{2})\d+?$/, '$1')
        );
    };

    const handleChangeFullName = (event: { target: { name: any; value: any; label: any } }) => {
        const { value } = event.target;
        const firstName = value.split(' ')[0];
        const lastName = value.split(' ').slice(1).join(' ');

        setFormFields({ ...formFields, firstName: value });

        if (lastName) {
            setErrorRegister({ ...errorRegister, errorFullName: false });
            setLastName(lastName);
            setFirstName(firstName);
        }
    };

    return (
        <>
            <Helmet>
                <title>{metaTags?.title}</title>
                <meta name="description" content={metaTags?.description} />
                <meta name="robots" content={metaTags?.robots} />
                <link rel="canonical" href={metaTags?.url_canonical} />
            </Helmet>
            <NewHeader hrefLogin={props.url.loginUrl} hrefRegister={'#'} />
            <Grid fullWidth condensed className="pageUpdateProfile">
                <Column
                    sm={4}
                    md={8}
                    lg={{ span: 10, offset: 1 }}
                    xlg={{ span: 10, offset: 1 }}
                    max={{ span: 8, offset: 2 }}
                >
                    <Row className="content">
                        <div className="side-text">
                            <ContentHeader title={'Cadastro'} description={description} />
                            <h6 className="subtitle-question">Já é cliente?</h6>
                            <ButtonLinkGtm
                                label="
                            Acesse sua conta
                            "
                                alt="Acesse sua conta"
                                title="Acesse sua conta"
                                className="link"
                                href={props.url?.loginRestartFlowUrl}
                            />
                        </div>
                        <Form className="form" method="post" action={props.url.loginAction}>
                            <div className="input-form">
                                <Input
                                    label={'Nome Completo'}
                                    placeholder="Digite aqui o seu nome completo"
                                    type="text"
                                    required
                                    onChange={handleChangeFullName}
                                    name="firstName"
                                    value={formFields.firstName}
                                    hasError={errorRegister?.errorFullName}
                                    errorMsg={errorRegister?.msgFullName}
                                />
                                <Input
                                    label={'E-mail'}
                                    placeholder="Digite aqui seu e-mail"
                                    type="text"
                                    required
                                    onChange={handleChange}
                                    name="email"
                                    value={formFields.email}
                                    hasError={errorRegister?.errorEmail}
                                    errorMsg={errorRegister?.msgEmail}
                                />
                                <Input
                                    label={'CPF'}
                                    placeholder="Apenas números"
                                    type="cpf"
                                    required
                                    onChange={handleChangeCPF}
                                    onBlur={() => {
                                        errorRegister?.errorCPF &&
                                            setErrorRegister({
                                                ...errorRegister,
                                                msgCPF: 'CPF inválido',
                                            });
                                    }}
                                    value={maskCPF(formFields.username)}
                                    hasError={errorRegister?.errorCPF}
                                    errorMsg={errorRegister?.msgCPF}
                                />
                                <input type="hidden" name="username" value={username} />
                                <Input
                                    label={'Telefone'}
                                    placeholder="(DD)____-____"
                                    type="phone"
                                    required
                                    onChange={handleChangeCellphone}
                                    value={formFields.user?.attributes.cellphone}
                                />
                                <input
                                    type="hidden"
                                    name="user.attributes.cellphone"
                                    value={cellphone}
                                />
                                <input type="hidden" name="user.attributes.active" value="true" />
                                <input type="hidden" name="firstName" value={firstName} />
                                <input type="hidden" name="lastName" value={lastName} />
                                <input type="hidden" name={social} />
                            </div>
                            <TermsOfUseModal text={conditionOne} required termsOfUse={termsUse} />
                            <Checkbox
                                labelText={conditionTwo}
                                id="notification"
                                name="user.attributes.notificar"
                            />
                            <Button
                                className="btn-confirm"
                                type="submit"
                                label="Finalizar cadastro"
                                variant="primary"
                                alt={'Finalizar cadastro'}
                                title={'Finalizar cadastro'}
                                size="large"
                            />
                        </Form>
                    </Row>
                </Column>
            </Grid>
            <NewFooter />
        </>
    );
}

export default LoginUpdateProfile;
