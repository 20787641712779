export const validateArray: TValidateArray = (array) => {
    if (array) {
        return JSON.parse(JSON.stringify(array));
    } else {
        return [];
    }
};

type TValidateArray = {
    <T>(array: T[] | null | undefined): T[];
};
