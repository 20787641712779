import { Checkbox as CarbonCheckbox } from 'carbon-components-react';
import { InputHTMLAttributes } from 'react';
import './Checkbox.scss';

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
    id: string;
    labelText: any;
}

export const Checkbox = ({
    id,
    labelText,
    name,
    className,
    onChange,
    checked,
    required,
}: Props) => {
    return (
        <div className="checkbox-wrapper">
            <CarbonCheckbox
                id={id}
                name={name}
                labelText={labelText}
                className={className}
                onChange={onChange}
                checked={checked}
                required={required}
            ></CarbonCheckbox>
        </div>
    );
};
