import './KeepLogged.scss';
import { Toggle } from 'carbon-components-react';

type KeepLoggedProps = {
    description?: string;
    name?: string;
    className?: string;
};

const KeepLogged = ({ description, name, className }: KeepLoggedProps) => (
    <label className="toggleLogin">
        <span className="description">{description}</span>
        <Toggle className={className} id="1" labelA="" labelB="" name={name} />
    </label>
);

export default KeepLogged;
