import styled from 'styled-components';

export const Container = styled.div`
    position: fixed;
    z-index: 100;
    top: 58px;
    display: flex;
    width: 83vw;
    align-items: center;
    justify-content: center;
    .bx--toast-notification {
        width: 100%;
    }

    .bx--toast-notification--low-contrast .bx--toast-notification__subtitle {
        color: #6d6e71;
        padding-top: 8px;
    }
`;
