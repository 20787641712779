export function receiveError(message: string) {
    const response: errorRegister = {
        errorFullName: false, //invalid full name
        msgFullName: undefined,
        errorEmail: false, //email already registered
        msgEmail: undefined,
        errorPassword: false, //password does not match
        msgPassword: undefined,
        errorCPF: false, //cpf already registered
        msgCPF: undefined,
    };

    if (message.match('Please specify last name.')) {
        response.errorFullName = true;
        response.msgFullName = 'Nome completo inválido';
    }
    if (message.match('Email already exists.')) {
        response.errorEmail = true;
        response.msgEmail = 'E-mail inválido';
    }
    if (message.match("Password confirmation doesn't match.")) {
        response.errorPassword = true;
        response.msgPassword = 'Senhas não coincidem';
    }
    if (message.match('Username already exists.')) {
        response.errorCPF = true;
        response.msgCPF = 'CPF inválido';
    }
    if (message.match('Please specify username.')) {
        response.errorCPF = true;
        response.msgCPF = 'CPF precisa ser preenchido';
    }
    if (message.match('Invalid email address.')) {
        response.errorEmail = true;
        response.msgEmail = 'E-mail inválido';
    }
    if (message.match('Invalid password: minimum length 8.')) {
        response.errorPassword = true;
        response.msgPassword = 'Senha deve conter ao menos 8 caracteres';
    }
    if (message.match('Invalid password: must contain at least 2 upper case characters.')) {
        response.errorPassword = true;
        response.msgPassword = 'Senha deve conter ao menos 2 caracteres maiúsculos ';
    }
    if (message.match('Invalid password: must contain at least 1 lower case characters.')) {
        response.errorPassword = true;
        response.msgPassword = 'Senha deve conter ao menos 1 caractere minúsculo ';
    }
    if (message.match('Invalid password: maximum length 12.')) {
        response.errorPassword = true;
        response.msgPassword = 'Senha deve conter no máximo 12 caracteres';
    }
    if (message.match('Invalid password: must contain at least 1 special characters.')) {
        response.errorPassword = true;
        response.msgPassword = 'Senha deve conter ao menos 1 caractere especial ';
    }
    if (message.match('Invalid password: must contain at least 1 numerical digits.')) {
        response.errorPassword = true;
        response.msgPassword = 'Senha deve conter ao menos 1 caractere numérico';
    }
    if (message.match('Invalid password: must not be equal to any of last 5 passwords.')) {
        response.errorPassword = true;
        response.msgPassword = 'Senha não deve ser igual as últimas 5 senhas';
    }

    return response;
}

export type errorRegister = {
    errorEmail?: boolean | undefined;
    msgEmail?: string | undefined;
    errorPassword?: boolean | undefined;
    msgPassword?: string | undefined;
    errorCPF?: boolean | undefined;
    msgCPF?: string | undefined;
    errorFullName?: boolean | undefined;
    msgFullName?: string | undefined;
};
