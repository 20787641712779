import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import './InputAuthentication.scss';

export const InputAuthentication = ({
    className,
    onChange,
    name,
    hasError,
    shouldAutoFocus,
    isInputNum,
}: InputAuthenticationProps) => {
    const [otp, setOtp] = useState('');

    return (
        <>
            <OtpInput
                value={otp}
                onChange={(value: React.SetStateAction<string>) => {
                    setOtp(value);
                    if (onChange) {
                        onChange(value);
                    }
                }}
                numInputs={6}
                separator={<span>&nbsp;</span>}
                containerStyle="input-style"
                focusStyle="focusInput"
                placeholder="123456"
                className={className}
                errorStyle="inputError"
                hasErrored={hasError}
                data-testid="input"
                shouldAutoFocus={shouldAutoFocus}
                isInputNum={isInputNum}
            />
            <input name={name} type="hidden" value={otp} />
        </>
    );
};

type InputAuthenticationProps = {
    className?: string;
    onChange?: any;
    name?: string;
    hasError?: boolean;
    shouldAutoFocus?: boolean;
    isInputNum?: boolean;
};
