import { Column, Form, Grid, Row } from 'carbon-components-react';
import { KcContextBase } from 'keycloakify';
import React, { useEffect, useState } from 'react';
import { Button } from '../../Component/Button/Button';
import { ContentHeader } from '../../Component/ContentHeader/ContentHeader';
import { CountdownTimer } from '../../Component/CountdownTimer/CountdownTimer';
import { InputAuthentication } from '../../Component/InputAuthentication/InputAuthentication';
import { NewHeader } from '../../Component/NewHeader/NewHeader';
import './UserAuthenticationConfirm.scss';
import { ButtonLinkGtm } from '../../Component/ButtonLinkGtm/ButtonLinkGtm';
import { AlertBanner } from '../../Component/AlertBanner/AlertBanner';
import { ArrowLeft24 } from '@carbon/icons-react';
import { ActionType, TagManagerUtils, UserLoggedStatus } from '../../TagManagerUtils';
import { InputPartners } from '../../Component/InputPartners';
import { Channels, KEY_STORAGE_CHANNEL } from '../../hook/useChannel';
import { NewFooter } from '../../Component/NewFooter/NewFooter';

function UserAuthenticationConfirm({ context, register }: Props) {
    const [refreshTimer, setRefreshTimer] = useState(false);
    const [awaitConfirm, setAwaitConfirm] = useState(true);
    const [errorMsgTimer, setErrorMsgTimer] = useState(false);
    const [invalidCodeMessage, setInvalidCodeMessage] = useState(false);
    const [errorNumberAttempts, setErrorNumberAttempts] = useState(false);
    const [codeExpiredMessage, setCodeExpiredMessage] = useState(false);
    const [change, setChange] = useState(false);
    const [timer, setTimer] = useState(
        context.message?.type === 'error' ? Number(localStorage.getItem('countdown-timer')) : 300,
    );
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [confirmRegistration, setConfirmRegistration] = useState(false);

    const storagedChannel = sessionStorage.getItem(KEY_STORAGE_CHANNEL) as Channels;
    const isAdvisoryChannel = storagedChannel === 'parceiros';

    // const errorMsg = context.message?.type === 'error';

    const onSubmit = () => {
        // console.log('onSubmit');
    };

    const onChange = (val: React.SetStateAction<string>) => {
        setChange(true);
    };

    useEffect(() => {
        context.message?.summary === 'loginAttemptsExceededMessage'
            ? setErrorNumberAttempts(true)
            : setErrorNumberAttempts(false);
        context.message?.summary === 'verificationInvalidCodeMessage'
            ? setInvalidCodeMessage(true)
            : setInvalidCodeMessage(false);
        context.message?.summary === 'verificationCodeExpiredMessage'
            ? setCodeExpiredMessage(true)
            : setCodeExpiredMessage(false);
    }, [context.message]);

    useEffect(() => {
        if (register) {
            setTitle('Confirmar cadastro');
            setDescription('Informe o código de verificação enviado para seu e-mail cadastrado.');
            setConfirmRegistration(true);
        } else {
            setTitle('Autenticação de usuário');
            setDescription('Para sua segurança, enviamos um código de verificação');
        }

        if (context.message?.type === 'error') {
            const localStorageTimer = Number(localStorage.getItem('countdown-timer'));
            setTimer(localStorageTimer);
        }
    }, []);

    useEffect(() => {
        if (register) {
            if (isAdvisoryChannel) {
                TagManagerUtils.pageview(
                    ActionType.PageView,
                    'cadastro',
                    UserLoggedStatus.LoggedOut,
                    'e-agro/confirmar-cadastro',
                    TagManagerUtils.getActionLocation(),
                );
            } else {
                TagManagerUtils.pageview(
                    ActionType.PageView,
                    'cadastro',
                    UserLoggedStatus.LoggedOut,
                    'e-agro/cadastro/codigo-verificacao',
                    TagManagerUtils.getActionLocation(),
                );
            }
        } else {
            TagManagerUtils.pageview(
                ActionType.PageView,
                'login',
                UserLoggedStatus.LoggedOut,
                'e-agro/autenticacao-codigo',
                TagManagerUtils.getActionLocation(),
            );
        }
    }, []);

    const onClickContinuar = () => {
        localStorage.setItem('countdown-timer', timer.toString());
        if (register) {
            if (isAdvisoryChannel) {
                TagManagerUtils.interact(
                    ActionType.Click,
                    'web',
                    'Continuar',
                    'cadastro',
                    UserLoggedStatus.LoggedOut,
                    'e-agro/confirmar-cadastro',
                );
            } else {
                TagManagerUtils.interact(
                    ActionType.Click,
                    'codigo-verificacao',
                    `botao-continuar`,
                    'cadastro',
                    UserLoggedStatus.LoggedOut,
                    'e-agro/cadastro/codigo-verificacao',
                );
            }
        } else {
            TagManagerUtils.interact(
                ActionType.Click,
                'web',
                'Continuar',
                'login',
                UserLoggedStatus.LoggedOut,
                'e-agro/autenticacao-codigo',
            );
        }
    };

    return (
        <>
            <NewHeader hrefLogin={context.url.loginUrl} hrefRegister={'#'} />
            <Grid fullWidth condensed className="pageUserAuthenticationConfirm">
                <Form onSubmit={onSubmit} action={context.url.loginAction} method="post">
                    <Row>
                        <Column
                            sm={4}
                            md={8}
                            lg={{ span: 10, offset: 1 }}
                            xlg={{ span: 10, offset: 1 }}
                            max={{ span: 8, offset: 2 }}
                        >
                            {!confirmRegistration ? (
                                <ButtonLinkGtm
                                    alt="voltar"
                                    title="voltar"
                                    Icon={ArrowLeft24}
                                    className="arrow-back"
                                    href={context.url.loginUrl}
                                />
                            ) : (
                                <div className="margin-top"></div>
                            )}
                            <Row className="content">
                                <ContentHeader
                                    className="content-header"
                                    title={title}
                                    description={description}
                                />
                                <div className="input-container">
                                    <InputAuthentication
                                        onChange={onChange}
                                        name="message"
                                        className="ipt-code"
                                        hasError={errorMsgTimer}
                                        shouldAutoFocus={true}
                                        isInputNum={true}
                                    />
                                    {!errorMsgTimer && (
                                        <CountdownTimer
                                            onStop={(aux) => setAwaitConfirm(!aux)}
                                            active={awaitConfirm}
                                            onRefresh={(aux) => setRefreshTimer(aux)}
                                            refresh={refreshTimer}
                                            className="timer"
                                            time={timer}
                                            label="tempo restante"
                                            setTime={setTimer}
                                            errorMsg={errorMsgTimer}
                                            setErrorMsg={setErrorMsgTimer}
                                        />
                                    )}
                                </div>
                                {invalidCodeMessage && (
                                    <>
                                        <span className="msg-error">
                                            O código digitado é inválido
                                        </span>
                                        <br />
                                    </>
                                )}
                                {errorNumberAttempts && (
                                    <>
                                        <span className="msg-error">
                                            Número de tentativas excedidas
                                        </span>
                                        <br />
                                    </>
                                )}
                                {codeExpiredMessage && (
                                    <>
                                        <span className="msg-error">Token expirado</span>
                                        <br />
                                    </>
                                )}
                                {errorMsgTimer && (
                                    <>
                                        <span className="msg-error">
                                            O tempo acabou, por favor envie outro código
                                        </span>
                                        <br />
                                    </>
                                )}

                                <div className="msg-container">
                                    <span className="msg-resend">
                                        Não recebeu o código de verificação?
                                    </span>
                                    <ButtonLinkGtm
                                        alt={'Reenviar código'}
                                        title={'Reenviar código'}
                                        className="btn-resend"
                                        label="Reenviar código"
                                        onClick={() => window.location.reload()}
                                    />
                                </div>
                                {confirmRegistration && (
                                    <>
                                        <AlertBanner
                                            className="alert-banner"
                                            text="A confirmação deve ser feita em até 10 minutos. Caso contrário, será necessário realizar um novo cadastro na plataforma."
                                        />

                                        <br />
                                    </>
                                )}

                                <div className="divider" />
                                <Button
                                    disabled={
                                        !change ||
                                        errorMsgTimer ||
                                        codeExpiredMessage ||
                                        errorNumberAttempts
                                    }
                                    className="btn-confirm"
                                    type="submit"
                                    label="Continuar"
                                    alt={'Continuar'}
                                    title={'Continuar'}
                                    variant="primary"
                                    size="large"
                                    onClick={() => onClickContinuar()}
                                />
                            </Row>

                            <InputPartners />
                        </Column>
                    </Row>
                </Form>
            </Grid>
            <NewFooter />
        </>
    );
}

export default UserAuthenticationConfirm;

type Props = {
    context: KcContextBase.LoginOtp | KcContextBase.LoginVerifyEmail;
    register?: boolean;
};
