import { useQuery, gql } from '@apollo/client';

export const useRegisterPage = () => {
    const QUERY_REGISTER = gql`
        query PageRegistration {
            pageRegistration {
                createdAt
                updatedAt
                publishedAt
                instruction
                condition_one
                condition_two
                terms_of_use
                terms_of_use_partner
                seo {
                    title
                    description
                    robots
                    url_canonical
                }
            }
        }
    `;

    const getRegister = useQuery(QUERY_REGISTER);

    return {
        getRegister: () => {
            return getRegister;
        },
    };
};
