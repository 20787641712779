import React from 'react';
import * as S from './ButtonLinkGtm.styles';

export const ButtonLinkGtm = ({ label, onClick, href, className, alt, title, Icon }: Props) => {
    return (
        // @ts-ignore
        <S.LinkButton onClick={onClick} href={href} className={className} alt={alt} title={title}>
            {label ? label : <Icon />}
        </S.LinkButton>
    );
};

type Props = {
    alt: string;
    title: string;
    className?: string;
    href?: string;
    label?: React.ReactNode;
    Icon?: any;
    onClick?: (e?: any) => void;
};
