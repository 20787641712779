import {
    LogoInstagram32 as LogoInstagram,
    LogoYoutube32 as LogoYoutube,
} from '@carbon/icons-react';
import { Column, Grid, Row } from 'carbon-components-react';
import React, { useEffect, useState } from 'react';
import { ActionType, TagManagerUtils, UserLoggedStatus } from '../../TagManagerUtils';
import AppleBadge from '../../assets/app-store-badge.svg';
import GoogleBadge from '../../assets/google-play-badge.png';
import { LogoFacebook } from '../../assets/icons/LogoFacebook';
import { LogoLinkedin } from '../../assets/icons/LogoLinkedin';
import { useGetHeader } from '../../hook/useGetHeader';
import { useNewFooter } from '../../hook/useNewFooter';
import * as S from './NewFooter.styles';

export const NewFooter = () => {
    const [socialMediaData, setSocialMediaData] =
        useState<{ label: string; icon: JSX.Element; path?: string }[]>();

    const linkStores = useNewFooter();
    const getLogoImage = useGetHeader();
    const { data: dataCms, loading } = linkStores.getFooter();
    const { data: logoUrl } = getLogoImage.getHeader();

    useEffect(() => {
        if (!loading && dataCms) {
            const socialMedia = [
                {
                    label: 'Instagram',
                    icon: <LogoInstagram color={'#6D6E71'} />,
                    path: dataCms?.footer.link_instagram,
                },
                {
                    label: 'Facebook',
                    icon: <LogoFacebook color={'#6D6E71'} />,
                    path: dataCms?.footer.link_facebook,
                },
                {
                    label: 'Linkedin',
                    icon: <LogoLinkedin color={'#6D6E71'} />,
                    path: dataCms?.footer.link_linkedin,
                },
                {
                    label: 'Youtube',
                    icon: <LogoYoutube color={'#6D6E71'} />,
                    path: dataCms?.footer.link_youtube,
                },
            ];

            setSocialMediaData(socialMedia);
        }
    }, [loading, dataCms]);

    const data = [
        {
            title: 'Sobre nós',
            values: [
                {
                    label: 'Página inicial',
                    path: `${process.env.REACT_APP_PUBLIC_URL}`,
                    alt: 'Página inicial',
                },
                {
                    label: 'Quem somos',
                    path: `${process.env.REACT_APP_PUBLIC_URL}sobre`,
                    title: 'Quem somos',
                },
                {
                    label: 'Privacidade',
                    path: `${process.env.REACT_APP_PUBLIC_URL}politica-de-privacidade`,
                    title: 'Privacidade',
                },
                {
                    label: 'Área do assessor',
                    path: `${process.env.REACT_APP_PUBLIC_URL}authenticator/login?canal=parceiros`,
                    title: 'Área do assessor',
                },
            ],
        },
        {
            title: 'Crédito',
            values: [
                {
                    label: 'Linhas de crédito',
                    path: `${process.env.REACT_APP_PUBLIC_URL}simulacao-de-credito`,
                    title: 'Linhas de crédito',
                },
                {
                    label: 'Simular CPR Digital',
                    path: `${process.env.REACT_APP_PUBLIC_URL}simulacao-de-cpr`,
                    title: 'Simular CPR Digital',
                },
                {
                    label: 'Simular Crédito Rural',
                    path: `${process.env.REACT_APP_PUBLIC_URL}simulacao-credito-rural`,
                    title: 'Simular Crédito Rural',
                },
            ],
        },
        {
            title: 'Produtos',
            values: [
                {
                    label: 'Ir para a loja',
                    path: `${process.env.REACT_APP_PUBLIC_URL}loja`,
                    title: 'Ir para a loja',
                },
                {
                    label: 'Meu carrinho',
                    path: `${process.env.REACT_APP_PUBLIC_URL}checkout`,
                    title: 'Meu carrinho',
                },
            ],
        },
    ];

    return (
        <>
            <S.FooterBorder />
            <Grid as={S.FooterGrid} fullWidth>
                <Row as={S.FooterRow}>
                    <Column sm={4} md={8} lg={2} xlg={2}>
                        <img
                            src={logoUrl?.onboard?.logo?.logo_e_agro_url}
                            alt={logoUrl?.onboard?.logo?.logo_e_agro_url_alt}
                            className="img-logo"
                        />
                    </Column>
                    <Column sm={4} md={8} lg={6} xlg={5}>
                        <Row>
                            {data.map((columns, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <Column key={columns.title} sm={4} md={2} lg={4} xlg={4}>
                                            <S.ColumnTitle key={columns.title}>
                                                {columns.title}
                                            </S.ColumnTitle>
                                            {columns.values.map((value) => {
                                                return (
                                                    <S.ColumnItem
                                                        key={value.label}
                                                        onClick={() => {
                                                            {
                                                                TagManagerUtils.overrideFirebaseScreenClass(
                                                                    'footer',
                                                                );
                                                                TagManagerUtils.interact(
                                                                    ActionType.Click,
                                                                    TagManagerUtils.getActionLocation(),
                                                                    `${value.label}`,
                                                                    'footer',
                                                                    UserLoggedStatus.LoggedOut,
                                                                    'e-agro/footer',
                                                                );
                                                            }
                                                            window.location.href = value.path;
                                                        }}
                                                    >
                                                        {value.label}
                                                    </S.ColumnItem>
                                                );
                                            })}
                                        </Column>
                                    </React.Fragment>
                                );
                            })}
                        </Row>
                    </Column>
                    <Column as={S.InfoColumn} sm={4} md={8} lg={3} xlg={5} fullWidth>
                        <S.InfoBorder />
                        <S.InfoContainer>
                            <div className={'column-top'}>
                                <S.InfoTitle>Siga o E-agro</S.InfoTitle>
                                <div className={'row'}>
                                    {socialMediaData?.map((item) => {
                                        return (
                                            <S.SocialMediaButton
                                                key={item.label}
                                                aria-label={item.label}
                                                className={
                                                    item?.path && item?.path?.length > 0
                                                        ? ''
                                                        : 'hide'
                                                }
                                                onClick={() => {
                                                    TagManagerUtils.interact(
                                                        ActionType.Click,
                                                        TagManagerUtils.getActionLocation(),
                                                        `${item.label}`,
                                                        'redes-sociais',
                                                        UserLoggedStatus.LoggedOut,
                                                        `e-agro/footer`,
                                                    );
                                                    window.open(item?.path);
                                                }}
                                            >
                                                {item.icon}
                                            </S.SocialMediaButton>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className={'column'}>
                                <S.InfoTitle>Baixe o app</S.InfoTitle>
                                <div className={'row'}>
                                    <div className={'column'}>
                                        <S.SocialMediaButton
                                            aria-label="Google Play"
                                            className={'no-margin'}
                                            onClick={() => {
                                                TagManagerUtils.interact(
                                                    ActionType.Click,
                                                    TagManagerUtils.getActionLocation(),
                                                    'Disponivel no google play',
                                                    'baixar-app',
                                                    UserLoggedStatus.LoggedOut,
                                                    `e-agro/footer`,
                                                );
                                                window.open(dataCms?.footer?.link_loja_google);
                                            }}
                                        >
                                            <S.StorefrontImage
                                                src={GoogleBadge}
                                                alt={'Google Play'}
                                            />
                                        </S.SocialMediaButton>
                                        <S.SocialMediaButton
                                            aria-label="App Store"
                                            className={'no-margin'}
                                            onClick={() => {
                                                TagManagerUtils.interact(
                                                    ActionType.Click,
                                                    TagManagerUtils.getActionLocation(),
                                                    'Disponivel na AppStore',
                                                    'baixar-app',
                                                    UserLoggedStatus.LoggedOut,
                                                    `e-agro/footer`,
                                                );
                                                window.open(dataCms?.footer?.link_loja_apple);
                                            }}
                                        >
                                            <S.StorefrontImage
                                                src={AppleBadge}
                                                alt={'Apple Store'}
                                            />
                                        </S.SocialMediaButton>
                                    </div>
                                    <S.QrCodeContainer>
                                        <S.QrCodeImage
                                            src={dataCms?.footer?.link_qr_code}
                                            alt={'Qr Code'}
                                        />
                                    </S.QrCodeContainer>
                                </div>
                            </div>
                        </S.InfoContainer>
                    </Column>
                </Row>
            </Grid>
            <S.BottomGrid>
                <S.BottomInfo>© 2022 e-agro - Soluções em Comércio Eletrônico S.A.</S.BottomInfo>
                <S.BottomInfo>CNPJ: 15.010.931/0001-48</S.BottomInfo>
                <S.BottomInfo>
                    Cidade de Deus, s/nº Vila Yara Osasco | SP | CEP: 06029-900.
                </S.BottomInfo>
            </S.BottomGrid>
        </>
    );
};
