import { useQuery, gql } from '@apollo/client';

export const useSEO = (page: string) => {
    const QUERY_SEO = gql`
    query Seo {
      ${page} {
        seo {
          title
          description
          robots
          url_canonical
        }
      }
    }
  `;

    const getSeo = useQuery(QUERY_SEO);
    return {
        getSEO: () => {
            return getSeo;
        },
    };
};
