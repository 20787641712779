import styled from 'styled-components';

export const Page = styled.div`
    @font-face {
        font-family: 'Bradesco Sans';
        font-style: normal;
        font-weight: 400;
        src: url('${process.env.REACT_APP_FONT_BRADESCO_REGULAR}') format('truetype');
    }

    @font-face {
        font-family: 'Bradesco Sans';
        font-style: normal;
        font-weight: 600;
        src: url('${process.env.REACT_APP_FONT_BRADESCO_SEMIBOLD}') format('truetype');
    }

    @font-face {
        font-family: 'Bradesco Sans';
        font-style: normal;
        font-weight: bold;
        src: url('${process.env.REACT_APP_FONT_BRADESCO_BOLD}') format('truetype');
    }

    body {
        font-family: Bradesco Sans;
    }

    code {
        font-family: Bradesco Sans, monospace !important;
    }

    ::-ms-reveal {
        display: none;
    }
`;
