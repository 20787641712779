import { ArrowLeft24 } from '@carbon/icons-react';
import { Column, Form, Grid, Loading, Row } from 'carbon-components-react';
import { KcContextBase } from 'keycloakify';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Button } from '../../Component/Button/Button';
import { ButtonLink } from '../../Component/ButtonLink/ButtonLink';
import { ButtonLinkGtm } from '../../Component/ButtonLinkGtm/ButtonLinkGtm';
import { Checkbox } from '../../Component/Checkbox/Checkbox';
import { ContentHeader } from '../../Component/ContentHeader/ContentHeader';
import { NewHeader } from '../../Component/NewHeader/NewHeader';
import Input from '../../Component/Input/Input';
import { PasswordValidation } from '../../Component/PasswordValidation/PasswordValidation';
import { TermsOfUseModal } from '../../Component/TermsOfUseModal/TermsOfUseModal';
import { useRegisterPage } from '../../hook/useRegisterPage';
import { ActionType, TagManagerUtils, UserLoggedStatus } from '../../TagManagerUtils';
import { errorRegister, receiveError } from '../../utils/receiveError';
import { isValidCPF } from '../../utils/validarCpf';
import './Register.scss';
import { OptinUtils } from '../../OptinUtils';
import {
    AplicacaoAcaoOptInEnum,
    PerfilUsuarioAcaoOptInEnum,
    JornadaOptInEnum,
} from '../../hook/types';
import { useSaveOptIn } from '../../hook/useSaveOptin';
import { InputPartners } from '../../Component/InputPartners';
import { useChannel } from '../../hook/useChannel';
import Notification from '../../Component/Notification/Notification';
import { NewFooter } from '../../Component/NewFooter/NewFooter';

const FIELDS_NAME = {
    email: 'email',
    password: 'password',
    cpf: 'CPF',
};

type formData = {
    firstName?: string | undefined;
    displayName?: string | undefined;
    lastName?: string | undefined;
    email?: string | undefined;
    username?: string | undefined;
    'user.attributes.cellphone'?: string | undefined;
    password?: string | undefined;
    'password-confirm'?: string | undefined;
};

function Register(props: KcContextBase.Register) {
    const url = window.location.href;

    const { channel } = useChannel();

    let DefaultFormFields = {
        firstName: '',
        email: '',
        username: '',
        'user.attributes.cellphone': '',
        password: '',
        'password-confirm': '',
    };

    const [errorRegister, setErrorRegister] = useState<errorRegister | undefined>(undefined);
    if (props.register?.formData)
        DefaultFormFields = {
            ...DefaultFormFields,
            ...props.register?.formData,
        };

    const [formFields, setFormFields] = useState<formData>(DefaultFormFields);
    const [username, setUsername] = useState(DefaultFormFields.username);
    const [cellphone, setCellphone] = useState(DefaultFormFields['user.attributes.cellphone']);
    const [errorPassword, setErrorPassword] = useState(false);
    const [errorConfirmPassword, setErrorConfirmPassword] = useState(false);
    const [errorConfirmPasswordMsg, setErrorConfirmPasswordMsg] = useState('');
    const [errorCellphone, setErrorCellphone] = useState(false);
    const [social, setSocial] = useState('');
    const [description, setDescription] = useState('');
    const [conditionOne, setConditionOne] = useState('');
    const [conditionTwo, setConditionTwo] = useState('');
    const [termsUse, setTermsUse] = useState('');
    const [passwordValidated, setPasswordValidated] = useState(false);
    const [hiddenValidation, setHiddenValidation] = useState(true);
    const [errorMsgLengthPassword, setErrorMsgLengthPassword] = useState('');
    const [errorLengthPassword, setErrorLengthPassword] = useState(false);
    const [metaTags, setMetaTags] = useState<any>({});
    const [dataSite, setDataSite] = useState<boolean>();
    const [termsOfUseChecked, setTermsOfUseChecked] = useState(false);
    const [sendOptin, setSendOption] = useState(false);
    const [errorNotification, setErrorNotification] = useState(false);
    const isAdvisoryChannel = channel === 'parceiros';

    const dataCMS = useRegisterPage();
    const saveOptIn = useSaveOptIn();

    useEffect(() => {
        setTimeout(() => {
            const { data, loading } = dataCMS.getRegister();

            if (!data) {
                setDataSite(false);
            } else {
                setDataSite(true);
            }

            if (!loading) {
                setMetaTags(data?.pageRegistration?.seo);
                setDescription(data.pageRegistration.instruction);
                setConditionOne(data.pageRegistration.condition_one);
                setConditionTwo(data.pageRegistration.condition_two);

                isAdvisoryChannel
                    ? setTermsUse(data.pageRegistration.terms_of_use_partner)
                    : setTermsUse(data.pageRegistration.terms_of_use);
            }
        }, 3000);
    }, [dataCMS]);

    const captureOptin = () => {
        if (!sendOptin) {
            const input = {
                aplicacaoAcaoOptIn: AplicacaoAcaoOptInEnum.appPortal,
                perfilUsuarioAcaoOptIn: PerfilUsuarioAcaoOptInEnum.cliente,
                nomeEtapaJornadaOptIn: JornadaOptInEnum.cadastroPlataforma,
                dadosCanalAcaoOptIn: OptinUtils.dadosCanalAcaoOptIn,
                dadosDispositivoAcaoOptIn: OptinUtils.dadosDispositivoAcaoOptIn,
                textoAcaoOptIn: termsUse,
            };
            saveOptIn.SaveOptIn(input);
            setSendOption(true);
        }
    };

    const handleChange = (event: { target: { name: any; value: any; label: any } }) => {
        const { name, value, label } = event.target;
        setFormFields({ ...formFields, [name]: value });

        if (errorRegister?.errorEmail && name === FIELDS_NAME.email) {
            setErrorRegister({ ...errorRegister, errorEmail: false });
        }
        if (errorPassword && name === FIELDS_NAME.password) {
            setErrorPassword(false);
        }
        if (errorRegister?.errorCPF && label === FIELDS_NAME.cpf) {
            setErrorRegister({ ...errorRegister, errorCPF: false });
        }
        if (label === 'CPF') {
            setFormFields({ ...formFields, username: value });
        } else if (label === 'Telefone') {
            setFormFields({ ...formFields, 'user.attributes.cellphone': value });
        }
    };

    const handleChangeCPF = (event: { target: { name: any; value: any; label: any } }) => {
        const { value } = event.target;
        setUsername(value);
        const newCpf = value
            .replace(/\D/g, '')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})/, '$1-$2')
            .replace(/(-d{2})\d+?$/, '$1');
        setFormFields({ ...formFields, username: newCpf });

        setErrorRegister({
            ...errorRegister,
            errorCPF: !isValidCPF(value),
        });
    };

    const handleChangeCellphone = (event: { target: { name: any; value: any; label: any } }) => {
        const { value } = event.target;
        setCellphone(value);
        setFormFields({ ...formFields, 'user.attributes.cellphone': value });
    };

    const handleChangeFirstName = (event: { target: { name: any; value: any; label: any } }) => {
        const { value } = event.target;

        const nameList = value?.split(' ');
        if (nameList.length === 1 || nameList[1].length === 0)
            setErrorRegister({
                ...errorRegister,
                errorFullName: true,
                msgFullName: 'Nome completo inválido',
            });
        else setErrorRegister({ ...errorRegister, errorFullName: false });

        setFormFields({ ...formFields, firstName: value });
    };

    const maskCPF = (cpf: string | undefined) => {
        return (
            cpf &&
            cpf
                .replace(/\D/g, '')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d{1,2})/, '$1-$2')
                .replace(/(-d{2})\d+?$/, '$1')
        );
    };

    const handleDisabled = () => {
        if (
            errorRegister?.errorCPF ||
            errorConfirmPassword ||
            errorCellphone ||
            errorRegister?.errorFullName ||
            !passwordValidated
        ) {
            return true;
        } else if (
            !formFields.password ||
            !formFields['password-confirm'] ||
            !formFields.firstName ||
            !formFields.email ||
            !formFields.username ||
            !formFields['user.attributes.cellphone'] ||
            !termsOfUseChecked
        ) {
            return true;
        } else {
            return false;
        }
    };

    useEffect(() => {
        if (!formFields.password || !formFields['password-confirm']) {
            setErrorConfirmPassword(false);
        } else if (formFields.password !== formFields['password-confirm']) {
            setErrorConfirmPassword(true);
            setErrorConfirmPasswordMsg('As senhas não coincidem');
        } else if (formFields.password === formFields['password-confirm']) {
            setErrorConfirmPassword(false);
        }
    }, [formFields.password, formFields['password-confirm']]);

    useEffect(() => {
        if (
            formFields['user.attributes.cellphone'] === undefined ||
            formFields['user.attributes.cellphone'].length > 9
        ) {
            setErrorCellphone(false);
        } else {
            setErrorCellphone(true);
        }
    }, [formFields['user.attributes.cellphone']]);

    useEffect(() => {
        formFields?.password && formFields?.password?.length > 12
            ? (setErrorLengthPassword(true),
              setErrorMsgLengthPassword('Senha deve conter no máximo 12 caracteres'))
            : (setErrorLengthPassword(false), setErrorMsgLengthPassword(''));
    }, [formFields?.password]);

    useEffect(() => {
        if (props.message?.summary) {
            if (props.message.summary.match('Advisor user is invalid for this register.')) {
                setErrorNotification(true);
            }
            const errors = receiveError(props.message?.summary);
            setErrorRegister(errors);
        }
        if (props.register?.formData) {
            setFormFields({
                ...props.register?.formData,
                'password-confirm': '',
                password: '',
            });
        }

        if (props.pageId === 'register.ftl') {
            setSocial('notSocial');
        }
    }, []);

    useEffect(() => {
        TagManagerUtils.pageview(
            ActionType.PageView,
            'cadastro',
            UserLoggedStatus.LoggedOut,
            'e-agro/cadastro',
            'web',
        );
    }, []);

    const termUser = (value: boolean) => {
        TagManagerUtils.interact(
            value ? ActionType.Activate : ActionType.Deactivate,
            'checkbox',
            'checkbox-termo-de-uso',
            'cadastro',
            UserLoggedStatus.LoggedOut,
            'e-agro/cadastro',
        );

        if (value) {
            captureOptin();
        }
    };

    function notificationChange(event: React.ChangeEvent<HTMLInputElement>) {
        TagManagerUtils.interact(
            event ? ActionType.Activate : ActionType.Deactivate,
            'checkbox',
            'checkbox-notificacoes',
            'cadastro',
            UserLoggedStatus.LoggedOut,
            'e-agro/cadastro',
        );
    }

    function onClickFinalizarCadastro() {
        if (isAdvisoryChannel) {
            TagManagerUtils.interact(
                ActionType.Click,
                'botao',
                'botao-finalizar-cadastro',
                'cadastro',
                UserLoggedStatus.LoggedOut,
                'e-agro/cadastro',
            );
        } else {
            TagManagerUtils.interact(
                ActionType.Click,
                'formulario-cadastro',
                'botao-finalizar-cadastro',
                'cadastro',
                UserLoggedStatus.LoggedOut,
                'e-agro/cadastro',
            );
        }
    }
    const registrationPhrase = isAdvisoryChannel ? 'Já é parceiro?' : 'Já é cliente?';

    return (
        <>
            <Helmet>
                <title>{metaTags?.title}</title>
                <meta name="description" content={metaTags?.description} />
                <meta name="robots" content={metaTags?.robots} />
                <link rel="canonical" href={metaTags?.url_canonical} />
            </Helmet>
            <NewHeader hrefLogin={props.url.loginUrl} hrefRegister={'#'} />
            {!dataSite && <Loading />}
            {dataSite && (
                <Grid fullWidth condensed className="pageRegister">
                    <Row className="content">
                        <Column
                            sm={4}
                            md={8}
                            lg={{ span: 10, offset: 1 }}
                            xlg={{ span: 10, offset: 1 }}
                            max={{ span: 8, offset: 2 }}
                        >
                            <div className="side-text">
                                <ButtonLinkGtm
                                    alt="voltar"
                                    title="voltar"
                                    Icon={ArrowLeft24}
                                    className="arrow-back"
                                    href={props.url.loginUrl}
                                />
                                <ContentHeader title={'Cadastro'} description={description} />
                                <h6 className="subtitle-question">{registrationPhrase}</h6>
                                <ButtonLink
                                    label="Acesse sua conta"
                                    className="link"
                                    href={props.url.loginUrl}
                                ></ButtonLink>
                            </div>
                            <Form
                                className="form"
                                method="post"
                                action={props.url.registrationAction}
                            >
                                <div className="input-form">
                                    <Input
                                        required
                                        label={'Nome Completo'}
                                        type="inputName"
                                        onChange={handleChangeFirstName}
                                        name="firstName"
                                        value={formFields.firstName}
                                        hasError={errorRegister?.errorFullName}
                                        errorMsg={errorRegister?.msgFullName}
                                    />
                                    <Input
                                        required
                                        label={'E-mail'}
                                        type="text"
                                        onChange={handleChange}
                                        name="email"
                                        value={formFields.email}
                                        hasError={errorRegister?.errorEmail}
                                        errorMsg={errorRegister?.msgEmail}
                                    />
                                    <div className="password-container">
                                        <Input
                                            className="input-password"
                                            required
                                            label={'Senha'}
                                            type="password"
                                            hasViewIcon
                                            name="password"
                                            onChange={handleChange}
                                            value={formFields?.password}
                                            onFocus={() => setHiddenValidation(false)}
                                            onBlur={() => setErrorPassword(!passwordValidated)}
                                            errorMsg={errorMsgLengthPassword}
                                            hasError={errorPassword || errorLengthPassword}
                                        />
                                        <Input
                                            className={
                                                !hiddenValidation ? 'input-confirm-password' : ''
                                            }
                                            required
                                            label={'Confirmar senha'}
                                            type="password"
                                            hasViewIcon
                                            onChange={handleChange}
                                            name="password-confirm"
                                            value={formFields['password-confirm']}
                                            hasError={errorConfirmPassword}
                                            errorMsg={errorConfirmPasswordMsg}
                                        />
                                        {!hiddenValidation && (
                                            <PasswordValidation
                                                className="password-validation"
                                                value={formFields?.password}
                                                setValidated={setPasswordValidated}
                                            />
                                        )}
                                    </div>
                                    <Input
                                        required
                                        label={'CPF'}
                                        type="cpf"
                                        onChange={handleChangeCPF}
                                        onBlur={() => {
                                            errorRegister?.errorCPF &&
                                                setErrorRegister({
                                                    ...errorRegister,
                                                    msgCPF: 'CPF inválido',
                                                });
                                        }}
                                        value={maskCPF(formFields.username)}
                                        hasError={errorRegister?.errorCPF}
                                        errorMsg={errorRegister?.msgCPF}
                                    />
                                    <input type="hidden" name="username" value={username} />
                                    <Input
                                        required
                                        label={'Telefone'}
                                        type="phone"
                                        onChange={handleChangeCellphone}
                                        value={formFields['user.attributes.cellphone']}
                                        hasError={errorCellphone}
                                    />
                                    <input
                                        required
                                        type="hidden"
                                        name="user.attributes.cellphone"
                                        value={cellphone}
                                    />
                                    <input type="hidden" name={social} />
                                </div>
                                <TermsOfUseModal
                                    text={conditionOne}
                                    required
                                    termsOfUse={termsUse}
                                    handleTag={(value: boolean) => {
                                        termUser(value);
                                    }}
                                    onChange={(value: boolean) => {
                                        console.log({ value });
                                        setTermsOfUseChecked(value);
                                    }}
                                />
                                <Checkbox
                                    labelText={conditionTwo}
                                    id="notification"
                                    name="user.attributes.notificar"
                                    onChange={(e) => notificationChange(e)}
                                />
                                <div className="divider" />
                                <Button
                                    className="btn-confirm"
                                    type="submit"
                                    label="Finalizar cadastro"
                                    variant="primary"
                                    size="large"
                                    alt={'Finalizar cadastro'}
                                    title={'Finalizar cadastro'}
                                    disabled={handleDisabled()}
                                    onClick={() => onClickFinalizarCadastro()}
                                />

                                <InputPartners />

                                {errorNotification && (
                                    <Notification
                                        title="Informações divergentes"
                                        subtitle="Alguma das informações preenchidas estão diferentes das informadas no seu pré-cadastro. Para mais informações entre em contato com o time comercial que realizou seu pré-cadastro"
                                        kind="error"
                                        timeout={20000}
                                        lowContrast
                                        onCloseButtonClick={() => setErrorNotification(false)}
                                    />
                                )}
                            </Form>
                        </Column>
                    </Row>
                </Grid>
            )}

            <NewFooter />
        </>
    );
}

export default Register;
