import styled from 'styled-components';
import carbonVariables from '../../utils/CarbonVars';

interface ValidationProps {
    done: boolean;
}

export const Container = styled.div`
    :first-child {
        margin: 100px;
    }
`;

export const Validation = styled.div<ValidationProps>`
    display: flex;

    align-items: center;
    gap: 0.5rem;

    color: ${carbonVariables.color08};

    &:not(:last-child) {
        margin-bottom: 1rem;
    }

    ${({ done }) =>
        done &&
        `svg {
            color: ${carbonVariables.color05};
        }
    `}
`;

export const IconContainer = styled.div``;
