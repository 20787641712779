import './ContentHeader.scss';

type ContentHeaderProps = {
    className?: string;
    title: string;
    description: string;
};

const ContentHeader = ({ className, title, description }: ContentHeaderProps) => (
    <div className={`content-header ${className}`}>
        <h1>{title}</h1>
        <p>{description}</p>
    </div>
);

export { ContentHeader };
