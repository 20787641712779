import React from 'react';

export const LogoLinkedin = ({ width = '24px', color = 'currentColor' }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 24.004 24">
        <defs>
            <clipPath id="clip-path">
                <rect
                    id="Retângulo_113423"
                    data-name="Retângulo 113423"
                    width={width}
                    height={width}
                />
            </clipPath>
        </defs>
        <g id="Grupo_146063" data-name="Grupo 146063" transform="translate(0 0)">
            <g
                id="Grupo_146038"
                data-name="Grupo 146038"
                transform="translate(0 0)"
                clipPath="url(#clip-path)"
            >
                <path
                    id="Caminho_713387"
                    data-name="Caminho 713387"
                    d="M0,11.684v-.457c.052-.408.042-.821.082-1.229A9.211,9.211,0,0,1,.719,7.584,12.093,12.093,0,0,1,9.5.152,11.631,11.631,0,0,1,10.981.025c.082-.006.169.026.246-.025h.407a1.487,1.487,0,0,0,.609,0H12.7c.425.05.855.042,1.281.084a8.973,8.973,0,0,1,2.433.645,12.2,12.2,0,0,1,6.966,7.2,10.4,10.4,0,0,1,.608,3.272,16.959,16.959,0,0,1-.088,2.77,11.22,11.22,0,0,1-1.662,4.436,11.74,11.74,0,0,1-1.619,2.047,12.068,12.068,0,0,1-3.661,2.558,10.211,10.211,0,0,1-3.031.9,20.507,20.507,0,0,1-3.146.059A10.532,10.532,0,0,1,6.532,22.78a11.643,11.643,0,0,1-2.665-1.84A12.109,12.109,0,0,1,.256,14.967a10.24,10.24,0,0,1-.231-2.02C.019,12.865.05,12.778,0,12.7v-.406a1.5,1.5,0,0,0,0-.61"
                    transform="translate(0 0)"
                    fill={color}
                />
                <path
                    id="Caminho_713388"
                    data-name="Caminho 713388"
                    d="M0,32.211a.826.826,0,0,1,0,.61Z"
                    transform="translate(0 -20.527)"
                    fill="#89b2da"
                />
                <path
                    id="Caminho_713389"
                    data-name="Caminho 713389"
                    d="M32.68,0a.824.824,0,0,1-.61,0Z"
                    transform="translate(-20.437 0)"
                    fill="#8ab3da"
                />
                <path
                    id="Caminho_713390"
                    data-name="Caminho 713390"
                    d="M35.4,24.876l.2.055a.65.65,0,0,1,.445.163l.471.291a.782.782,0,0,1,.411.411,4.909,4.909,0,0,1,.456.866,2.676,2.676,0,0,1,.22.773.85.85,0,0,0,.025.21c-.012.181.064.354.054.535q0,2.8,0,5.6a2.768,2.768,0,0,0,.008.329c.021.08-.026.122-.086.157-.667.046-1.335.013-2,.017a2.922,2.922,0,0,1-.757-.028c-.078-.052-.059-.131-.059-.206,0-1.2,0-2.4,0-3.605,0-.6-.01-1.2-.048-1.8l-.012-.1a1.977,1.977,0,0,1-.145-.507c.008-.03-.007-.046-.035-.054a.253.253,0,0,1-.07-.2.438.438,0,0,0-.146-.211c-.095-.033-.143-.119-.208-.185a.934.934,0,0,0-.61-.259,5.26,5.26,0,0,1-.641.021,2.062,2.062,0,0,0-.815.329.972.972,0,0,0-.424.752,4.391,4.391,0,0,0-.122.481,1.149,1.149,0,0,1-.013.2,2.4,2.4,0,0,0-.015.4q0,2.3,0,4.6a.558.558,0,0,1-.04.321.376.376,0,0,1-.243.044q-1.123,0-2.245,0c-.09,0-.188.019-.262-.059,0-.219-.009-.439-.009-.658q0-4.245,0-8.49c0-.11.006-.219.009-.329.069-.074.159-.037.238-.037.74,0,1.48,0,2.22,0,.148-.012.211.054.208.2-.006.25-.005.5,0,.749,0,.052-.058.179.1.132.13-.151.223-.34.433-.406l.469-.293a.64.64,0,0,1,.446-.164l.155-.052a2.1,2.1,0,0,1,.605-.144,1.486,1.486,0,0,0,.376-.024c.141,0,.283,0,.424,0a1.84,1.84,0,0,0,.421.024,1.926,1.926,0,0,1,.61.144"
                    transform="translate(-18.285 -15.739)"
                    fill="#f7f7f7"
                />
                <path
                    id="Caminho_713391"
                    data-name="Caminho 713391"
                    d="M17.583,13.54a1.933,1.933,0,0,1-.653,1.183c-.038.052-.078.1-.153.1l-.305.156a1.289,1.289,0,0,1-1.117,0l-.511-.3c-.122-.005-.165-.108-.233-.183-.023-.086-.144-.12-.123-.23l-.154-.306a1.3,1.3,0,0,1,.017-1.146,1.873,1.873,0,0,1,1.008-.984,1.359,1.359,0,0,1,1.315.1,1.774,1.774,0,0,1,.907,1.337.278.278,0,0,1,0,.28"
                    transform="translate(-9.056 -7.456)"
                    fill="#f7f7f7"
                />
                <path
                    id="Caminho_713392"
                    data-name="Caminho 713392"
                    d="M45.311,35.568a3.087,3.087,0,0,1,.073.879c.005,1.477,0,2.955,0,4.432a.573.573,0,0,0,.024.274.029.029,0,0,1,.02.041l-.017.018c-.132-.1-.106-.239-.106-.371,0-1.576,0-3.153,0-4.729,0-.181-.1-.363,0-.544"
                    transform="translate(-28.848 -22.666)"
                    fill="#4982be"
                />
                <path
                    id="Caminho_713393"
                    data-name="Caminho 713393"
                    d="M36.228,41.837q0-2.32.005-4.639c0-.11.006-.219.009-.329.035-.065,0-.139.029-.2a.048.048,0,0,1,.062,0q0,2.458,0,4.916c0,.118.011.24-.1.321l-.019-.018a.052.052,0,0,1,.017-.046"
                    transform="translate(-23.076 -23.358)"
                    fill="#528cc6"
                />
                <path
                    id="Caminho_713394"
                    data-name="Caminho 713394"
                    d="M14.251,34.347a.317.317,0,0,1-.107-.3q0-4.507,0-9.014A.375.375,0,0,1,14.2,24.8l.039.031a.461.461,0,0,1,.075.314c0,3.026.01,6.052,0,9.077a.289.289,0,0,1-.028.095l-.034.036"
                    transform="translate(-9.013 -15.801)"
                    fill="#4a82c0"
                />
                <path
                    id="Caminho_713395"
                    data-name="Caminho 713395"
                    d="M23.352,24.921q0,4.6,0,9.2a.322.322,0,0,1-.105.3l-.034-.036a.287.287,0,0,1-.029-.095c-.015-1.278,0-2.556-.006-3.834q0-2.527,0-5.053a.688.688,0,0,1,.077-.465.086.086,0,0,1,.092-.019"
                    transform="translate(-14.77 -15.877)"
                    fill="#4274ba"
                />
                <path
                    id="Caminho_713396"
                    data-name="Caminho 713396"
                    d="M28.377,25.074c.1,0,.046.082.046.118q.005,4.528,0,9.057a1.029,1.029,0,0,1-.011.125s-.013.009-.018.008-.013-.01-.019-.015a.424.424,0,0,1-.023-.223q0-4.423,0-8.845a.425.425,0,0,1,.023-.224"
                    transform="translate(-18.067 -15.979)"
                    fill="#4880bf"
                />
                <path
                    id="Caminho_713397"
                    data-name="Caminho 713397"
                    d="M28.336,25.074v9.292a.566.566,0,0,1-.049-.321q0-4.325,0-8.65a.567.567,0,0,1,.049-.321"
                    transform="translate(-18.026 -15.979)"
                    fill="#5e98cb"
                />
                <path
                    id="Caminho_713398"
                    data-name="Caminho 713398"
                    d="M53.314,40.431l.048-.085a.282.282,0,0,1-.02-.149c0-1.907,0-3.813,0-5.72.01-.04.033-.052.071-.036q0,2.851,0,5.7c0,.126.018.258-.106.346l-.017-.018a.029.029,0,0,1,.02-.041"
                    transform="translate(-33.961 -21.944)"
                    fill="#4a82c0"
                />
                <path
                    id="Caminho_713399"
                    data-name="Caminho 713399"
                    d="M17.7,24.751h-.05a.632.632,0,0,1-.367.07c-.86,0-1.72,0-2.581,0-.141,0-.291.015-.4-.115v0a.409.409,0,0,1,.267-.053h2.805c.117,0,.24-.016.32.1"
                    transform="translate(-9.114 -15.707)"
                    fill="#467cbf"
                />
                <path
                    id="Caminho_713400"
                    data-name="Caminho 713400"
                    d="M17.673,50.986a.479.479,0,0,1-.27.048H14.7a.482.482,0,0,1-.27-.047l-.005-.007a.364.364,0,0,1,.285-.073q1.34,0,2.681,0a.391.391,0,0,1,.287.067Z"
                    transform="translate(-9.192 -32.441)"
                    fill="#5f99cc"
                />
                <path
                    id="Caminho_713401"
                    data-name="Caminho 713401"
                    d="M48.444,51.064a.438.438,0,0,1-.244.047H45.9a.434.434,0,0,1-.244-.047l0-.008c.536-.047,1.074-.017,1.612-.018a9.06,9.06,0,0,1,1.183.019Z"
                    transform="translate(-29.094 -32.518)"
                    fill="#5f99cc"
                />
                <path
                    id="Caminho_713402"
                    data-name="Caminho 713402"
                    d="M31.45,51.064a.43.43,0,0,1-.244.049H28.953a.429.429,0,0,1-.244-.05.783.783,0,0,1,.348-.024c.715,0,1.43,0,2.146,0a.555.555,0,0,1,.248.02Z"
                    transform="translate(-18.295 -32.52)"
                    fill="#5f99cc"
                />
                <path
                    id="Caminho_713403"
                    data-name="Caminho 713403"
                    d="M31.451,51l-2.742,0,0-.06,2.735,0,0,.059"
                    transform="translate(-18.295 -32.464)"
                    fill="#4071b8"
                />
                <path
                    id="Caminho_713404"
                    data-name="Caminho 713404"
                    d="M31.255,24.7l-2.537.012a.393.393,0,0,1,.288-.065l2.249,0c.018.011.024.022.018.033s-.012.017-.018.017"
                    transform="translate(-18.301 -15.707)"
                    fill="#467cbf"
                />
                <path
                    id="Caminho_713405"
                    data-name="Caminho 713405"
                    d="M18.638,11.859a1.929,1.929,0,0,0-1.315-.1c-.04-.022-.046-.056,0-.07a1.774,1.774,0,0,1,1.214.031.176.176,0,0,1,.1.135"
                    transform="translate(-11.018 -7.393)"
                    fill="#558dc6"
                />
                <path
                    id="Caminho_713406"
                    data-name="Caminho 713406"
                    d="M41.085,24.73l-.61.1a1.062,1.062,0,0,1,.738-.189c.361-.027.727-.007,1.09-.007a.045.045,0,0,1-.025.071,1.4,1.4,0,0,1-.41-.017.988.988,0,0,1-.4,0c-.124.049-.259-.005-.383.039"
                    transform="translate(-25.793 -15.695)"
                    fill="#538bc5"
                />
                <path
                    id="Caminho_713407"
                    data-name="Caminho 713407"
                    d="M17.366,20.73a2.146,2.146,0,0,0,1.117,0,.21.21,0,0,1-.175.126,2.533,2.533,0,0,1-.769,0,.205.205,0,0,1-.173-.127"
                    transform="translate(-11.067 -13.21)"
                    fill="#5990c8"
                />
                <path
                    id="Caminho_713408"
                    data-name="Caminho 713408"
                    d="M14.287,14.764a2.06,2.06,0,0,0-.017,1.146,1.048,1.048,0,0,1,.017-1.146"
                    transform="translate(-8.99 -9.408)"
                    fill="#558cc6"
                />
                <path
                    id="Caminho_713409"
                    data-name="Caminho 713409"
                    d="M35.712,24.707v-.05c.137.033.152.139.152.254,0,.209,0,.419,0,.628,0,.076-.034.177.091.2l.005.021-.108.2-.052,0c0-.329,0-.658,0-.987,0-.093.046-.21-.089-.26"
                    transform="translate(-22.758 -15.713)"
                    fill="#467cbe"
                />
                <path
                    id="Caminho_713410"
                    data-name="Caminho 713410"
                    d="M37.993,31.744a1.132,1.132,0,0,1,.751-.348.064.064,0,0,1,.048.043l-.8.3"
                    transform="translate(-24.211 -20.007)"
                    fill="#568dc6"
                />
                <path
                    id="Caminho_713411"
                    data-name="Caminho 713411"
                    d="M52.861,30.888l-.188-.777c.226.169.156.448.246.667-.037.028,0,.091-.058.111"
                    transform="translate(-33.566 -19.188)"
                    fill="#578ec7"
                />
                <path
                    id="Caminho_713412"
                    data-name="Caminho 713412"
                    d="M53.362,33.29h-.046c-.042-.022-.019-.073-.046-.1,0-.172-.1-.335-.05-.511a.067.067,0,0,1,.044-.049,2.785,2.785,0,0,1,.1.662"
                    transform="translate(-33.907 -20.792)"
                    fill="#5288c4"
                />
                <path
                    id="Caminho_713413"
                    data-name="Caminho 713413"
                    d="M45.469,24.7V24.65c.2.081.447,0,.609.195l-.608-.1c-.036-.014-.036-.029,0-.044"
                    transform="translate(-28.959 -15.708)"
                    fill="#558dc6"
                />
                <path
                    id="Caminho_713414"
                    data-name="Caminho 713414"
                    d="M50.694,27l-.411-.411a.467.467,0,0,1,.411.411"
                    transform="translate(-32.043 -16.945)"
                    fill="#4c84c2"
                />
                <path
                    id="Caminho_713415"
                    data-name="Caminho 713415"
                    d="M44.938,33.892a.967.967,0,0,1,.145.507.547.547,0,0,1-.145-.507"
                    transform="translate(-28.632 -21.598)"
                    fill="#548cc6"
                />
                <path
                    id="Caminho_713416"
                    data-name="Caminho 713416"
                    d="M36.392,27l-.005-.021c.085-.184.176-.362.414-.381l-.408.4"
                    transform="translate(-23.188 -16.947)"
                    fill="#5088c4"
                />
                <path
                    id="Caminho_713417"
                    data-name="Caminho 713417"
                    d="M48.2,25.451l-.445-.163a.349.349,0,0,1,.445.163"
                    transform="translate(-30.434 -16.097)"
                    fill="#558bc5"
                />
                <path
                    id="Caminho_713418"
                    data-name="Caminho 713418"
                    d="M39.265,25.286l-.446.164a.357.357,0,0,1,.446-.164"
                    transform="translate(-24.738 -16.097)"
                    fill="#5189c4"
                />
                <path
                    id="Caminho_713419"
                    data-name="Caminho 713419"
                    d="M40.111,31.4l-.047-.043a2.591,2.591,0,0,1,.7,0,2.263,2.263,0,0,1-.658.045"
                    transform="translate(-25.531 -19.964)"
                    fill="#4071b9"
                />
                <path
                    id="Caminho_713420"
                    data-name="Caminho 713420"
                    d="M36.436,34.9a.649.649,0,0,1,.141-.466,1.1,1.1,0,0,1-.1.465c-.013.037-.026.037-.041,0"
                    transform="translate(-23.219 -21.94)"
                    fill="#4d86c3"
                />
                <path
                    id="Caminho_713421"
                    data-name="Caminho 713421"
                    d="M23.506,16.279c0-.094,0-.187,0-.28.121.087.046.2.038.3a.062.062,0,0,1-.02-.005.105.105,0,0,1-.017-.017"
                    transform="translate(-14.979 -10.195)"
                    fill="#4173ba"
                />
                <path
                    id="Caminho_713422"
                    data-name="Caminho 713422"
                    d="M15.289,19.327l.21.208c-.147.046-.181-.085-.251-.16-.028-.052,0-.055.042-.049"
                    transform="translate(-9.709 -12.315)"
                    fill="#4b83c1"
                />
                <path
                    id="Caminho_713423"
                    data-name="Caminho 713423"
                    d="M43.689,32.1a.423.423,0,0,1,.208.185c-.141.019-.165-.094-.208-.185"
                    transform="translate(-27.841 -20.459)"
                    fill="#5088c4"
                />
                <path
                    id="Caminho_713424"
                    data-name="Caminho 713424"
                    d="M15.1,18.973l-.042.049c-.053-.077-.161-.131-.1-.253l.145.2"
                    transform="translate(-9.518 -11.96)"
                    fill="#5288c4"
                />
                <path
                    id="Caminho_713425"
                    data-name="Caminho 713425"
                    d="M36.374,35.713h.041c0,.118,0,.235,0,.353l-.039-.007c-.055-.046-.037-.1-.015-.152l.014-.193"
                    transform="translate(-23.157 -22.758)"
                    fill="#4071b9"
                />
                <path
                    id="Caminho_713426"
                    data-name="Caminho 713426"
                    d="M53.254,32.195l-.044.049c-.006-.062-.012-.123-.018-.185l.057-.111,0,.247"
                    transform="translate(-33.897 -20.359)"
                    fill="#4478bc"
                />
                <path
                    id="Caminho_713427"
                    data-name="Caminho 713427"
                    d="M21.287,20.133l.153-.1c-.024.074-.045.153-.153.1"
                    transform="translate(-13.565 -12.767)"
                    fill="#5a8fc7"
                />
                <path
                    id="Caminho_713428"
                    data-name="Caminho 713428"
                    d="M44.631,33.195a.27.27,0,0,1,.07.2c-.064-.052-.121-.106-.07-.2"
                    transform="translate(-28.429 -21.154)"
                    fill="#487ec0"
                />
                <path
                    id="Caminho_713429"
                    data-name="Caminho 713429"
                    d="M53.379,34.173l.046.1q0,2.762,0,5.524c0,.127-.008.253-.012.379a.7.7,0,0,1-.022-.123q-.009-2.941-.016-5.883"
                    transform="translate(-34.016 -21.777)"
                    fill="#5b95cb"
                />
                <path
                    id="Caminho_713430"
                    data-name="Caminho 713430"
                    d="M48.449,51.017H45.655c0-.017,0-.034,0-.05h2.79l0,.052"
                    transform="translate(-29.094 -32.478)"
                    fill="#4071b8"
                />
                <path
                    id="Caminho_713431"
                    data-name="Caminho 713431"
                    d="M44.752,24.79c0,.015,0,.029,0,.044a1.169,1.169,0,0,1-.435-.041l.435,0"
                    transform="translate(-28.241 -15.798)"
                    fill="#5b95cb"
                />
                <path
                    id="Caminho_713432"
                    data-name="Caminho 713432"
                    d="M36.32,36.246l.015.152-.052.211a.7.7,0,0,1,.036-.363"
                    transform="translate(-23.117 -23.098)"
                    fill="#5b95cb"
                />
                <path
                    id="Caminho_713433"
                    data-name="Caminho 713433"
                    d="M42.156,24.828a.752.752,0,0,1,.383-.039.93.93,0,0,1-.383.039"
                    transform="translate(-26.864 -15.793)"
                    fill="#5b95cb"
                />
                <path
                    id="Caminho_713434"
                    data-name="Caminho 713434"
                    d="M14.3,24.806l3.046.012a.549.549,0,0,1,.3.033c0,.186-.009.371-.009.557q0,4.3,0,8.6c0,.1.032.2-.038.294a.167.167,0,0,1-.1.026H14.443a.148.148,0,0,1-.094-.029c-.069-.091-.037-.2-.037-.294q0-4.408,0-8.816c0-.127,0-.253-.007-.38"
                    transform="translate(-9.114 -15.808)"
                    fill="#f7f7f7"
                />
                <path
                    id="Caminho_713435"
                    data-name="Caminho 713435"
                    d="M14.426,50.968l3.249,0c0,.015,0,.029,0,.044l-3.253.005c0-.017,0-.034,0-.051"
                    transform="translate(-9.192 -32.48)"
                    fill="#4071b8"
                />
            </g>
        </g>
    </svg>
);

type Props = {
    color?: string;
    width?: string;
};
