import { useChannel } from '../../hook/useChannel';

export function InputPartners() {
    const { channel } = useChannel();

    if (channel !== 'parceiros') {
        return null;
    }

    return <input type="hidden" name="canal" value="parceiros" />;
}
