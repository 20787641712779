import styled from 'styled-components';
import carbonVariables from '../../utils/CarbonVars';

export const DarkerBackground = styled.div`
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.65);
`;

export const LateralMenuContainer = styled.div`
    background: ${carbonVariables.bg03};
    height: 100%;
    width: 18rem;
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
`;

export const LinkContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: 0.5rem;

    & > a.link-header {
        color: ${carbonVariables.color04};
        font-size: 14px;
        margin: 0.5rem;
        height: fit-content;
        text-decoration: none;
    }

    & > a.link-header:hover,
    & > a.link-header.active-route {
        border-bottom: 2px solid ${carbonVariables.color12};
        padding-bottom: 0.25rem;
        margin-top: 0.25rem;
    }

    & > a.link-header.active-route {
        margin-bottom: 0;
    }

    & > svg {
        fill: ${carbonVariables.color01};
        width: 1.25rem;
        height: 1.25rem;
    }

    & > p {
        cursor: pointer;
        font-size: 14px;
        margin-left: 0.5rem;
    }

    & > p::after {
        content: '>';
        margin-left: 0.5rem;
    }
`;

export const CategoryContainer = styled.div`
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    margin-left: 1rem;
    padding-bottom: 0.75rem;
    margin-bottom: 0.75rem;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.65);

    & > svg {
        fill: ${carbonVariables.color12};
    }
`;

export const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-left: 1rem;
    margin-bottom: 1rem;

    & > p {
        font-weight: 600;
    }

    & > svg {
        cursor: pointer;
    }
`;

export const GoBackRow = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;

    & > svg {
        fill: ${carbonVariables.color12};
    }

    & > p {
        font-size: 14px;
        margin-left: 0.5rem;
    }
`;

export const Title = styled.p``;

export const Banner = styled.img`
    width: 208px;
    height: 400px;
    margin-left: 1rem;
`;
