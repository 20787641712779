import styled from 'styled-components';

export const LinkButton = styled.a`
    font-family: 'Bradesco Sans';
    color: #0c881e;
    font-size: 14px;
    font-weight: bold;
    background: none;
    border: none;
    text-decoration: none;
    cursor: pointer;
    &:hover {
        color: #075512;
    }
`;
