import './Input.scss';
import { InputHTMLAttributes, useState } from 'react';
import { ViewOff20, View20 } from '@carbon/icons-react';

export const Input = ({
    label,
    type,
    placeholder,
    hasError,
    hasViewIcon,
    errorMsg,
    className,
    name,
    onChange,
    refControl,
    onBlur,
    value,
    required,
    onFocus,
    autoFocus,
    autoComplete,
}: InputProps) => {
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const [cpf, setCpf] = useState(value);
    const [phone, setPhone] = useState(value);
    const [inputName, setInputName] = useState(value);

    const onChangeMask = (event: any) => {
        const valueModify = event.target.value;
        const callOnChange = (valueName?: string) => {
            if (onChange) {
                if (type === 'inputName') {
                    event.target.value = valueName;
                    onChange(event);
                } else {
                    onChange({ ...event, target: { value: valueModify.replace(/\D/g, '') } });
                }
            }
        };
        if (type === 'cpf') {
            const newCpf = event.target.value
                .replace(/\D/g, '')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d{1,2})/, '$1-$2')
                .replace(/(-d{2})\d+?$/, '$1');
            setCpf(newCpf);
            callOnChange();
        } else if (type === 'phone') {
            const newPhone = event.target.value
                .replace(/\D/g, '')
                .replace(/^(\d{2})(\d)/g, '($1) $2')
                .replace(/(\d)(\d{4})$/, '$1-$2');
            setPhone(newPhone);
            callOnChange();
        } else if (type === 'inputName') {
            const newInputName = event.target.value.replace(
                /[^a-zA-Z ´`ˆ'˜àáâäãåąčćÇçęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑ ]/g,
                '',
            );

            setInputName(newInputName);
            callOnChange(newInputName);
        }
    };

    const handleMaxLength = (): number => {
        if (type === 'cpf') {
            return 14;
        } else if (type === 'phone') {
            return 15;
        } else {
            return 256;
        }
    };

    const handleValue = (): any => {
        if (type === 'cpf') {
            return cpf;
        } else if (type === 'phone') {
            return phone;
        } else {
            return inputName;
        }
    };

    return (
        <div data-testid="input-id" className={`input-container ${className}`}>
            <label className="label">{label}</label>
            <div className="content-label">
                {type === 'cpf' || type === 'phone' || type === 'inputName' ? (
                    <input
                        data-testid="mask-id"
                        className={hasError ? 'inputError input' : 'input'}
                        type={'text'}
                        placeholder={placeholder}
                        name={name}
                        ref={refControl}
                        onChange={(e) => {
                            onChangeMask(e);
                        }}
                        required={required}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        maxLength={handleMaxLength()}
                        minLength={type === 'cpf' ? 14 : 0}
                        value={handleValue()}
                    />
                ) : (
                    <input
                        className={hasError ? 'inputError input' : 'input'}
                        type={hasViewIcon && !passwordShown ? 'password' : 'text' || type}
                        placeholder={placeholder}
                        name={name}
                        ref={refControl}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        required={required}
                        onFocus={onFocus}
                        autoFocus={autoFocus}
                        autoComplete={autoComplete}
                    />
                )}
                {hasViewIcon ? (
                    <div data-testid="toogle" onClick={togglePassword} className="icon">
                        {hasViewIcon && passwordShown ? <View20 /> : hasViewIcon && <ViewOff20 />}
                    </div>
                ) : (
                    <></>
                )}
            </div>
            {hasError && errorMsg ? <div className="messageError">{errorMsg}</div> : ''}
        </div>
    );
};

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    className?: string;
    label: string;
    type: string;
    placeholder?: string;
    hasViewIcon?: boolean;
    hasError?: boolean;
    errorMsg?: string;
    name?: string;
    onChange?: any;
    onBlur?: any;
    refControl?: any;
    // maxLength?: (e: any) => void;
    value?: any;
    onFocus?: any;
    autoFocus?: any;
    autoComplete?: string;
}

export default Input;
