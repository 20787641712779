import styled from 'styled-components';
import carbonVariables from '../../utils/CarbonVars';
type Props = {
    logo_url?: string;
};

export const FooterBorder = styled.div`
    margin: auto;
    margin-top: 2rem;
    border-top: 1px solid ${carbonVariables.color12};

    @media (min-width: 1312px) {
        width: 78rem;
    }

    @media (min-width: 1056px) and (max-width: 1312px) {
        width: 64rem;
    }

    @media (min-width: 320px) and (max-width: 1056px) {
        margin: 0 1rem;
    }
`;

export const InfoBorder = styled.div`
    margin: auto;

    @media (max-width: 1055px) {
        border-top: 1px solid ${carbonVariables.color07};
    }
`;

export const FooterGrid = styled.div`
    padding: 40px 0 40px 0;
    background-color: ${carbonVariables.bg08};
    margin: 0;
`;

export const FooterRow = styled.div`
    @media (min-width: 1312px) {
        width: 80rem;
    }

    @media (min-width: 1056px) and (max-width: 1312px) {
        width: 66rem;
    }

    @media (min-width: 320px) and (max-width: 1056px) {
        width: 100%;
    }

    margin: auto;
    max-width: 80rem;
    display: flex;
    justify-content: space-between;

    .img-logo {
        @media (min-width: 1056px) {
            margin-right: 1.5625rem;
        }
        object-fit: contain;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        height: 3.5rem;
        width: 7.125rem;
        cursor: pointer;
    }
`;

export const ColumnTitle = styled.p`
    margin-bottom: 16px;
    padding-top: 16px;
    font-weight: ${carbonVariables.fontWeight03};
    font-size: ${carbonVariables.fontSize02};
`;

export const ColumnItem = styled.a`
    border: none;
    text-decoration: none;
    color: ${carbonVariables.color10};
    display: flex;
    margin-bottom: 8px;
    font-size: ${carbonVariables.fontSize01};
    font-weight: normal;
    align-items: center;
    text-align: left;
    cursor: pointer;
    transition: all 0.3s ease;
    height: 1rem;
    width: 10rem;

    :hover {
        color: ${carbonVariables.color12};

        .arrow-icon {
            margin-left: 6px;
            display: block;
            transition: transform 2000ms;
            transform: translateX(0.25rem);
        }
    }

    &:after {
        content: '→';
        opacity: 0;
    }

    &:hover:after {
        opacity: 1;
        transition: transform 500ms;
        transform: translateX(1rem);
    }

    .arrow-icon {
        display: none;
    }
`;

export const SocialMediaButton = styled.button`
    border: none;
    text-decoration: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0 8px 0 0;
    background-color: ${carbonVariables.bg08};

    .hide {
        display: none;
    }

    .no-margin {
        margin: 0px;
    }
`;

export const InfoTitle = styled.p`
    margin-bottom: 8px;
    font-size: ${carbonVariables.fontSize07};
    color: ${carbonVariables.color10};
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (min-width: 1312px) {
        margin-left: 3rem;
    }

    @media (min-width: 1056px) and (max-width: 1312px) {
        flex-direction: column;
        margin-left: 1rem;
    }

    @media (max-width: 1055px) {
        justify-content: flex-start;
        padding-top: 24px;
    }

    @media (max-width: 671px) {
        flex-direction: column;
    }

    .row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }

    .column {
        display: flex;
        flex-direction: column;
    }

    .column-top {
        display: flex;
        flex-direction: column;

        @media (min-width: 1056px) and (max-width: 1312px) {
            margin-bottom: 1rem;
        }

        @media (max-width: 1055px) {
            margin-right: 3rem;
        }

        @media (max-width: 671px) {
            margin-bottom: 1rem;
        }
    }
`;

export const StorefrontImage = styled.img`
    width: 119px;
`;

export const QrCodeContainer = styled.div`
    display: flex;
    background-color: ${carbonVariables.color03};
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    width: 80px;
    height: 80px;
`;

export const QrCodeImage = styled.img`
    width: 72px;
    height: 72px;
    align-self: center;
`;

export const InfoColumn = styled.div`
    @media (max-width: 1055px) {
        margin-top: 24px;
    }

    @media (min-width: 1056px) {
        border-left: 1px solid ${carbonVariables.color07};
    }
`;

export const Image = styled.div<Props>`
    background-image: url('${({ logo_url }) => logo_url}');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    height: 3.5rem;
    width: 7.125rem;
    cursor: pointer;
    @media (min-width: 1056px) {
        margin-right: 1.5625rem;
    }
`;

export const BottomGrid = styled.div`
    width: 100%;
    background-color: ${carbonVariables.bg01};
    margin: 0 0 0 0;
    padding: 20px;
    display: flex;
    justify-content: center;

    @media (max-width: 1055px) {
        justify-content: flex-start;
        flex-direction: column;
    }
`;

export const BottomInfo = styled.p`
    font-size: ${carbonVariables.fontSize07};
    color: ${carbonVariables.color03};
    margin: 0 2rem 0 0;

    @media (max-width: 1055px) {
        text-align: left;
        margin: 0px;
    }
`;
