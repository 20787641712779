import React, { useEffect, useState } from 'react';
import './CountdownTimer.scss';

type CountdownTimerProps = {
    label?: string;
    time: number;
    className?: string;
    active?: boolean;
    onStop?: (e: any) => void;
    refresh?: boolean;
    onRefresh?: (e: any) => void;
    setTime: (e: any) => void;
    errorMsg?: boolean;
    setErrorMsg: (e: any) => void;
};

export const CountdownTimer = ({
    label,
    time,
    className,
    onStop,
    active,
    refresh,
    onRefresh,
    setTime,
    errorMsg,
    setErrorMsg,
}: CountdownTimerProps) => {
    const [timer, setTimer] = useState('00:00');
    const [isStop, setIsStop] = useState(false);

    function ClearAllIntervals() {
        for (let i = 1; i < 99999; i += 1) clearInterval(i);
    }

    const startTimer = () => {
        let countAux = time;

        return setInterval(function interval() {
            const minutes = Math.trunc(countAux / 60)
                .toString()
                .padStart(2, '0');
            const seconds = (countAux % 60).toString().padStart(2, '0');
            if (countAux >= 0 && active) {
                countAux -= 1;
                setTimer(`${minutes}:${seconds}`);
                setTime(countAux);
            } else {
                ClearAllIntervals();
                setIsStop(true);
            }
        }, 1000);
    };

    useEffect(() => {
        if (active) {
            setIsStop(false);
            if (!refresh) {
                startTimer();
            }
        } else {
            ClearAllIntervals();
        }
    }, [active]);

    useEffect(() => {
        if (onStop && isStop) {
            onStop(isStop);
            setErrorMsg(true);
        }
    }, [isStop]);

    useEffect(() => {
        if (refresh) {
            if (onRefresh) {
                setIsStop(false);
                onRefresh(false);
            }
            ClearAllIntervals();
            startTimer();
        }
    }, [refresh]);

    return (
        <div className={className} data-testid="countdown-timer-id">
            <span>{`${timer} ${label}`}</span>
            {/* {errorMsg ? (
                <>
                    <span className="msg-error">O código digitado é inválido</span>
                    <br />
                </>
            ) : (
                <></>
            )} */}
        </div>
    );
};
