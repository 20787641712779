import { gql, useMutation } from '@apollo/client';
import { AplicacaoAcaoOptInEnum, JornadaOptInEnum, PerfilUsuarioAcaoOptInEnum } from './types';

export const useSaveOptIn = () => {
    const [SaveOptIn] = useMutation(gql`
        mutation SaveOptIn($orderId: ID, $input: saveOptInInput) {
            saveOptIn(orderId: $orderId, input: $input) {
                statusCode
                mensagem
            }
        }
    `);

    return {
        SaveOptIn: async (input: Iinput) => {
            return SaveOptIn({
                variables: { input: input },
            });
        },
    };
};

export type InformacoesComplementares = {
    texto: string | null;
    tipo: string | null;
};
interface Iinput {
    aplicacaoAcaoOptIn: AplicacaoAcaoOptInEnum;
    perfilUsuarioAcaoOptIn: PerfilUsuarioAcaoOptInEnum;
    nomeEtapaJornadaOptIn: JornadaOptInEnum;
    dadosCanalAcaoOptIn?: any;
    dadosDispositivoAcaoOptIn?: any;
    textoAcaoOptIn: any;
    informacoesComplementares?: InformacoesComplementares[];
}
