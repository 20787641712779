export enum AplicacaoAcaoOptInEnum {
    appPortal = 'APP_PORTAL',
}

export enum PerfilUsuarioAcaoOptInEnum {
    cliente = 'CLIENTE',
}

export enum JornadaOptInEnum {
    cadastroPlataforma = 'CADASTRO_PLATAFORMA',
}
