import { Link } from 'carbon-components-react';
import './ButtonLink.scss';

export const ButtonLink = ({ label, onClick, className, href }: Props) => {
    return (
        <Link href={href} onClick={onClick} className={`btn-link ${className}`}>
            {label}
        </Link>
    );
};

type Props = {
    className?: string;
    label?: string;
    onClick?: (e?: any) => void;
    href?: any;
};
