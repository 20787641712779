import { Information16, Misuse16, Warning16 } from '@carbon/icons-react';
import { Text } from '../Text/Text';
import * as S from './AlertBanner.styles';

export const AlertBanner = ({ text, variant, className }: InfoProps) => {
    return (
        <S.AlertBannerContainer variant={variant} className={className}>
            <S.IconContainer>
                {(!variant || variant === 'info') && <Information16 />}
                {variant === 'error' && <Misuse16 />}
                {variant === 'warning' && <Warning16 className="warning" />}
            </S.IconContainer>
            <Text text={text} color="color09" size="fontSize01" weight="fontWeight01" />
        </S.AlertBannerContainer>
    );
};

export type InfoProps = {
    text: string;
    className?: string;
    variant?: 'info' | 'error' | 'warning';
};
