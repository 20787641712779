import './PasswordReset.scss';
import { Form, Grid, Row } from 'carbon-components-react';
import Input from '../Input/Input';
import { useState, useEffect } from 'react';
import { Button } from '../Button/Button';
import { ContentHeader } from '../ContentHeader/ContentHeader';
import { PasswordValidation } from '../PasswordValidation/PasswordValidation';

export const PasswordReset = ({
    action,
    username,
    title,
    description,
    errorMsg,
    error,
}: PasswordUpdateProps) => {
    // eslint-disable-next-line prefer-const
    const [errorMessage, setErrorMessage] = useState('');
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
    const [hasError, setHasError] = useState(false);
    const [disabled, setDisabled] = useState(true);

    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [passwordValidated, setPasswordValidated] = useState(false);
    const [hiddenValidation, setHiddenValidation] = useState(true);
    const [errorValidation, setErrorValidation] = useState(false);

    const onSubmit = () => {
        // console.log('onSubmit');
    };

    useEffect(() => {
        if (!password || !passwordConfirm || !passwordValidated || error) {
            setDisabled(true);
            setHasError(false);
        }

        if (password.length > 12) {
            setDisabled(true);
            setHasError(true);
            setPasswordErrorMessage('Senha deve conter no máximo 12 caracteres');
        } else {
            setPasswordErrorMessage('');
        }

        if (password !== passwordConfirm) {
            setDisabled(true);
            setHasError(true);
            setErrorMessage('As senhas não coincidem');
        } else {
            setErrorMessage('');
        }

        if (password === passwordConfirm && passwordValidated && password.length <= 12) {
            setDisabled(false);
            setHasError(false);
        }
    }, [password, passwordConfirm, passwordValidated, error]);

    return (
        <Grid className="pageResetPasswordConfirm">
            <Row className="row-content">
                <Form action={action} onSubmit={onSubmit} method="post">
                    <ContentHeader
                        title={title}
                        description={description}
                        className="content-header"
                    />
                    <Input
                        label={'Nova Senha'}
                        type="password"
                        name="password-new"
                        onChange={(e: any) => {
                            setPassword(e.target.value), setErrorValidation(false);
                        }}
                        className="inputPassword"
                        hasViewIcon
                        onFocus={() => setHiddenValidation(false)}
                        onBlur={() => setErrorValidation(!passwordValidated)}
                        placeholder="Digite aqui a sua nova senha"
                        errorMsg={errorMsg || passwordErrorMessage}
                        hasError={errorValidation || error || !!passwordErrorMessage}
                    />
                    {!hiddenValidation && (
                        <PasswordValidation
                            value={password}
                            setValidated={setPasswordValidated}
                            className="password-validation"
                        />
                    )}
                    <Input
                        label={'Confirmar nova senha'}
                        type="password"
                        name="password-confirm"
                        onChange={(e: any) => setPasswordConfirm(e.target.value)}
                        hasViewIcon
                        placeholder="Confirme aqui a sua nova senha"
                        errorMsg={errorMessage}
                        hasError={hasError}
                    />
                    <input name="username" type="hidden" value={username} />
                    <div
                        className={
                            hiddenValidation ? 'btn-container btn-with-margin' : 'btn-container'
                        }
                    >
                        <Button
                            disabled={disabled}
                            type="submit"
                            onClick={() => onSubmit()}
                            variant="primary"
                            size="large"
                            label="Continuar"
                            alt={'Continuar'}
                            title={'Continuar'}
                        ></Button>
                    </div>
                </Form>
            </Row>
        </Grid>
    );
};

type PasswordUpdateProps = {
    action: string;
    username?: string;
    title: string;
    description: string;
    errorMsg: string;
    error: boolean;
};

export default PasswordReset;
