import { ToastNotification, ToastNotificationProps } from 'carbon-components-react';
import * as S from './Notification.styles';

function Notification(props: ToastNotificationProps) {
    return (
        <S.Container>
            <ToastNotification {...props} />
        </S.Container>
    );
}

export default Notification;
