export function setCookie(name: string, value: any, duration?: any, path = '/', domain?: string) {
    let cookie = name + '=' + value;
    if (duration) {
        cookie += '; expires=' + duration.toGMTString();
    }
    cookie += '; path=' + path;
    if (domain) {
        cookie += '; domain=' + domain;
    }
    document.cookie = cookie;
}

export function getCookie(name: string) {
    const cookies = document.cookie;
    const prefix = name + '=';
    let begin = cookies.indexOf('; ' + prefix);

    if (begin == -1) {
        begin = cookies.indexOf(prefix);

        if (begin != 0) {
            return null;
        }
    } else {
        begin += 2;
    }

    let end = cookies.indexOf(';', begin);

    if (end == -1) {
        end = cookies.length;
    }

    return cookies.substring(begin + prefix.length, end);
}

export function deleteCookie(name: string) {
    if (getCookie(name)) {
        document.cookie = name + '=' + '; expires=Thu, 01-Jan-70 00:00:01 GMT';
    }
}
