/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

const CarbonVars: { [key: string]: string | undefined } = {
    // Background colors
    bg01: '#0C881E',
    bg02: '#0670BF',
    bg03: '#FFFFFF',
    bg04: '#000000',
    bg05: '#0BCC56',
    bg06: '#F8FFFB',
    bg07: '#F5F7FD',
    bg08: '#F7F7F7',
    bg09: '#E5E6E7',

    // Main colors
    color01: '#0C881E',
    color02: '#0670BF',
    color03: '#FFFFFF',
    color04: '#000000',
    color05: '#0BCC56',

    //Neutral colors
    color06: '#F0F0F0',
    color07: '#D9DCDD',
    color08: '#A7A8AC',
    color09: '#6D6E71',
    color10: '#47484C',
    color11: '#00000029',
    color12: '#008AEB',

    //Alert colors and others
    colorInfo: '#3B69FF',
    colorSuccess: '#0BCC56',
    colorWarning: '#FFBC00',
    colorError: '#E1173E',
    colorVisited: '#8838FF',
    colorAlertLight: '#FCF7F8',

    // Dark main colors
    color01Dark: '#075512',
    color02Dark: '#034E88',

    // Gradient colors
    colorGrad01: 'linear-gradient(180deg, #5CC85F 0%, #034E88 100%)',
    colorGrad02: 'linear-gradient(180deg, #5CC85F 0%, #0F8C21 100%)',
    colorGrad03: 'linear-gradient(1deg, #008AEB 0%, #034E88 100%)',
    colorGrad04: 'linear-gradient(270deg, #008AEB 0%, #034E88 100%)',

    // Font sizes
    fontSize01: '14px',
    fontSize02: '16px',
    fontSize03: '18px',
    fontSize04: '22px',
    fontSize05: '27px',
    fontSize06: '32px',
    fontSize07: '12px',

    // Font weights
    fontWeight01: 'normal',
    fontWeight02: 'bold',
    fontWeight03: '600',
};

export default CarbonVars;
