// export function removeAccents(str: string) {
//     return str?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '')!;
// }

export function removeAccents(str: string | undefined | null): string {
    if (!str) {
        return '';
    }

    const normalizedStr = str.normalize('NFD');
    const withoutAccents = normalizedStr.replace(/[\u0300-\u036f]/g, '');

    return withoutAccents;
}

export const normalizeString = (value: string | undefined | null) => {
    if (value)
        return value
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toUpperCase();
    return '';
};

export function normalizeArrayValue<T>(value: Array<T & { name: string | null | undefined }>) {
    const normalizedArray = value.map((item) => {
        return { ...item, name: normalizeString(item.name) };
    });

    return normalizedArray;
}

export const sanitizeURL = (url: string) => {
    try {
        const urlObj = new URL(url);

        const pathSegments = urlObj.pathname.split('/').filter((segment) => segment !== '');

        const sanitizedPathSegments = pathSegments.map((segment) => encodeURIComponent(segment));

        const sanitizedPathname = '/' + sanitizedPathSegments.join('/');

        const sanitizedQueryParams = new URLSearchParams();
        urlObj.searchParams.forEach((value, key) => {
            sanitizedQueryParams.append(key, encodeURIComponent(value));
        });

        const sanitizedURL = `${urlObj.origin}${sanitizedPathname}${
            sanitizedQueryParams.toString().length > 0 ? '?' : ''
        }${sanitizedQueryParams.toString()}${urlObj.hash}`;

        return sanitizedURL;
    } catch (_) {
        return '';
    }
};
