/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { useQuery, gql } from '@apollo/client';

export const useDropdownCategoriasBanner = () => {
    const BANNER = gql`
        query GetMenuMarketplace {
            getMenuMarketplace {
                image_url
                image_url_mobile
                title
                alt
                link
            }
        }
    `;

    const { called, loading, data, error, refetch } = useQuery(BANNER, {
        fetchPolicy: 'no-cache',
    });

    return { called, loading, data, error, refetch };
};
