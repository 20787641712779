import styled from 'styled-components';

interface Props {
    variant: string;
    alt: string;
    size: string;
    isDisabled?: boolean;
}

export const ButtonWrapper = styled.a<Props>`
    cursor: pointer;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 0px;
    border-radius: 30px;
    font-weight: 'bold';

    ${({ variant }) =>
        variant === 'primary' &&
        `
    background-color: #0C881E;
  color: #FFFFFF;
  border: none;

  :hover {
    background-color: #075512;
    color: #FFFFFF;
    border: none;
  }

  &.bx--btn:disabled {
    background-color: #A7A8AC;
    color: #6D6E71;
  }
  `};

    ${({ variant }) =>
        variant === 'secondary' &&
        `
  background-color: #FFFFFF;
  color: #0C881E;
  border: 2px solid #0C881E;

  :hover {
    background-color: #FFFFFF;
    color: #075512;
    border: 2px solid #075512;
  }

  &.bx--btn:disabled {
    background-color: #FFFFFF;
    color: #6D6E71;
  }
  `};

    ${({ isDisabled }) =>
        isDisabled &&
        `
      cursor: not-allowed;
      color: #6D6E71;
      border-color: #6D6E71;
      background-color: #A7A8AC;
      :hover{
        cursor: not-allowed;
        color: #6D6E71;
        border-color: #6D6E71;
        background-color: #A7A8AC;
      }
`}

    ${({ size }) =>
        size === 'small' &&
        `
      width: 6.25rem; 
      height: 3.12rem; 
  `};

    ${({ size }) =>
        size === 'medium' &&
        `
      width: 12.5rem; 
      height: 3.12rem; 
  `};

    ${({ size }) =>
        size === 'long' &&
        `
      width: 15.5rem; 
      height: 3.12rem; 
  `};

    ${({ size }) =>
        size === 'large' &&
        `
      width: 18.75rem; 
      height: 3.12rem; 
  `};
`;
