/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import styled from 'styled-components';
import { ButtonGtm } from '../ButtonGtm/ButtonGtm';

type Props = {
    logo_url?: string;
};

export const Header = styled.div``;

export const HeaderFirstRow = styled.div`
    background-color: #ffffff;
    height: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid rgb(247, 247, 247);
`;

export const HeaderFirstRowContainer = styled.div`
    @media (min-width: 1312px) {
        width: 78rem;
    }

    @media (min-width: 1056px) and (max-width: 1312px) {
        width: 64rem;
    }

    @media (min-width: 320px) and (max-width: 1056px) {
        width: 100%;
        padding: 0 1rem;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;

    & > svg {
        cursor: pointer;
        width: 1.3125rem;
        height: 1.3125rem;
    }

    .smaller-icon {
        width: 1.75rem;
        height: 1.75rem;
    }

    .left-icon {
        margin-right: 2rem;
    }

    .chevronDown-up {
        transform: rotate(-0.5turn);
    }
`;

export const CategoryContainer = styled.div`
    svg.categorias {
        display: none;
    }

    svg.chevronDown,
    svg.chevronDown-up {
        margin-left: 0.25rem;
        color: #0c881e;
    }

    p.categorias {
        color: #000000;
    }

    #separator {
        border: none;
        border-bottom: 2px solid #008aeb;
        top: 0;
    }

    margin: 0 1.25rem;
`;

export const HeaderFirstRowContainerLeft = styled.div`
    display: flex;
    align-items: center;

    & > a.link-header {
        color: #000000;
        font-size: 14px;
        font-weight: 400;
        margin: 0 1.25rem;
        height: fit-content;
        text-decoration: none;
    }

    & > a:hover,
    & > p:hover,
    & > a.active-route {
        border-bottom: 2px solid #008aeb;
        padding-bottom: 0.25rem;
        margin-top: 0.25rem;
    }

    #categs {
        height: 3.5rem;
    }

    #separator {
        display: none;
    }

    @media (max-width: 1056px) {
        display: none;
    }
`;

export const HeaderFirstRowContainerRight = styled.div`
    .btn-signin {
        margin-right: 1rem;
    }

    display: flex;
    align-items: center;
    position: relative;

    @media (max-width: 1056px) {
        display: none;
        color: blue;
    }

    & > svg {
        cursor: pointer;
    }
`;

export const HeaderSecondRow = styled.div`
    background-color: #f7f7f7;
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1056px) {
        height: 6rem;
    }
`;

export const HeaderSecondRowContainer = styled.div``;

export const ButtonLinkGtm = styled(ButtonGtm)`
    @media (min-width: 1056px) and (max-width: 1312px) {
        width: 7.125rem;
    }

    width: 8.875rem;
    height: 2rem;
`;

export const UserMenu = styled.div`
    height: 9rem;
    width: 9.5rem;
    background: white;
    position: absolute;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    top: 3rem;
    right: -1rem;
    z-index: 1;
    padding: 1rem;
    border-radius: 4px;

    &:before {
        content: '';
        display: block;
        position: absolute;
        right: 1rem;
        top: -15px;
        border-bottom: 15px solid #fff;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
    }

    @media (max-width: 1056px) {
        top: 4rem;
        right: 0rem;
    }
`;

export const UserName = styled.div`
    & > p {
        font-weight: 600;
    }

    padding-bottom: 0.5rem;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
`;

export const MenuItem = styled.div`
    margin: 1rem 0;
    cursor: pointer;
    display: flex;
    align-items: center;

    & > svg {
        margin-right: 0.25rem;
    }
`;

export const Image = styled.div<Props>`
    background-image: url('${({ logo_url }) => logo_url}');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    height: 3.5rem;
    width: 7.125rem;
    cursor: pointer;
    @media (min-width: 1056px) {
        margin-right: 1.5625rem;
    }
`;

export const ImageBlock = styled.img`
    height: 3.5rem;
    width: 7.125rem;
    object-fit: contain;
`;
