import { useEffect, useState } from 'react';

export type Channels = 'clientes' | 'parceiros';

export const KEY_STORAGE_CHANNEL = 'channel';

export function useChannel() {
    const [channel, setChannel] = useState<Channels>('clientes');

    function saveChannel(channelValue: Channels) {
        sessionStorage.setItem(KEY_STORAGE_CHANNEL, channelValue);

        setChannel(channelValue);
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.href);
        const hasChannelParam = searchParams.has('canal');
        const channelParam = searchParams.get('canal') as Channels;

        const storagedChannel = sessionStorage.getItem(KEY_STORAGE_CHANNEL) as Channels;

        if (!hasChannelParam) {
            setChannel(storagedChannel);

            return;
        }

        saveChannel(channelParam || 'clientes');
    }, []);

    return { channel, saveChannel };
}
