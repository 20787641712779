import styled from 'styled-components';
import carbonVariables from '../../utils/CarbonVars';

export const Banner = styled.img`
    width: 208px;
    height: 400px;
`;

export const CategeoryColumn = styled.div`
    width: 220px;
    max-height: 100%;
    display: flex;

    flex-direction: column;
`;

export const CategTitle = styled.h2`
    text-align: left;
    font: normal normal 600 16px/24px Bradesco Sans;
    letter-spacing: 0px;
    color: ${carbonVariables.color04};
    max-width: 200px;
    margin-right: 14px !important;
    width: fit-content;

    a {
        color: ${carbonVariables.color04};
    }
`;
export const Dropdown = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    display: inline-block;
`;

export const CategAndUpContainer = styled.div`
    display: flex;
    align-items: center;

    p {
        font: normal normal normal 14px/17px Bradesco Sans;
        font-size: 14px;
    }
`;

export const TextHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    top: 1.7px;

    .chevronDown-up {
        transform: rotate(-0.5turn);
    }

    #separator {
        border: 2px solid ${carbonVariables.bg01};
        position: relative;
        top: 9px;
        width: 100%;
        height: 2px;
    }

    #separator-hidden {
        border: 1px solid transparent;
        position: relative;
        top: 9px;
        width: 100%;
        height: 2px;
    }
`;

export const SeparatorBelowCategsText = styled.div``;

export const DropdownContent = styled.div<{ show: boolean }>`
    ${(props) => (props.show ? `display:block;` : `display:none;`)}

    position: absolute;
    top: 55.5px;
    width: 500vw;
    height: 500vw;
    z-index: 998;

    .overlay {
        background: rgba(0, 0, 0, 0.5) no-repeat padding-box;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 997;
    }

    @media (min-width: 1313px) {
        left: -1250px;
    }

    @media (min-width: 1056px) {
        left: -1250px;
    }
`;
export const SubCategText = styled.li`
    font: normal normal normal 14px/17px Bradesco Sans;
    letter-spacing: 0.16px;
    color: ${carbonVariables.color04};
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    margin-bottom: 8px;
    width: fit-content;

    .link {
        font: normal normal normal 14px/17px Bradesco Sans;
        letter-spacing: 0.16px;
        color: ${carbonVariables.color04};
        cursor: pointer;
        display: inline;
        box-sizing: border-box;
        word-wrap: break-word;
        width: fit-content;
        word-wrap: break-word;
        white-space: normal;
    }

    .link-title {
        font: normal normal normal 14px/17px Bradesco Sans;
        letter-spacing: 0.16px;
        color: ${carbonVariables.color04};
        cursor: pointer;
    }
`;

export const Drop = styled.div`
  z-index: 998;
  color: black;
  width: 100%;
  height: 100%;

  .content {
    cursor: default;
    width: 770px;
    height: 452px;
    z-index: 1009;
    display: none;

    background-color: ${carbonVariables.bg08};
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0px;
    left: 1000px;

    @media (max-width: 1076px) and (max-width: 1170px) {
      top: 0px;
      left: 1450px;
    }

    @media (max-width: 1076px) {
      top: 0px;
      left: 915px;
    }

    opacity: 1;
    border-radius: 0px 0px 8px 8px;

    .separator {
      background-color: ${carbonVariables.bg09};
      height: 400px;
      width: 1px;
      margin: 0px 32px 0 0px;
    }

    .text-container {
      width: 457px;
      height: 400px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      position: relative;

      .title-grid {
        display: inline-flex;
        width: fit-content;
        flex-direction: column;
        text-align: left;
        margin-right: 15px;
        margin-bottom: 15px;

        .arrow-hidden {
          opacity: 0;
        }
      }

      ul {
        text-align: left;
        width: 100%;

        display: inline-flex;
        flex-direction: column;

        .first {
          margin-top: 5px;
        }

        .normal-li {
          margin-top: 5px;
          width: 70%;
        }
      }
      #long-text {
        align-items: center;
      }

      .not-hovered {
        color: ${carbonVariables.color12} !important;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;

        img {
          opacity: 0;
          max-width: 12px;
          max-height: 9px;
          margin-left: 9px;
          margin-top: 3px;
        }
      }

      #long-text-hovered {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;

        img {
          max-width: 12px;
          max-height: 9px;
          margin-left: 9px;
          margin-top: auto;
          margin-bottom: auto;
          margin-right: 0px;
        }
      }

      .hovered {
        color: ${carbonVariables.color12} !important;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;

        img {
          max-width: 12px;
          max-height: 9px;
          margin-left: 9px;
          margin-top: 3px;
        }
      }
    }

    .low-size-container-to-txts {
      width: 100%;
      height: 408px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    .text-container-low-size {
      width: 100%;
      max-height: 400px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: flex-start;
      position: relative;
      top: 15px;
      justify-content: flex-start;
      margin-left: 32px;
      margin-right: -32px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 4px;
        background-color: transparent;
        border-radius: 4px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.2);
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(136, 136, 136);
      }

      &::-webkit-scrollbar-thumb:hover {
        border-radius: 4px;
        background-color: rgba(161, 161, 161);
      }


      .title-grid {
        display: inline-flex;
        width: 232px;
        flex-direction: column;
        text-align: left;
        margin-bottom: 1rem;

        .arrow-hidden {
          opacity: 0;
        }
      }

      ul {
        text-align: left;
        width: fit-content;

        margin-bottom: 4px;
        display: inline-flex;
        flex-direction: column;

        .first {
          margin-top: 5px;
        }

        #long-text {
          width: 70%;
          align-items: center;
        }
      }

      .not-hovered {
        color: ${carbonVariables.color20} !important;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: first baseline;

        img {
          opacity: 0;
          max-width: 12px;
          max-height: 9px;
          margin-left: 9px;
          margin-top: 3px;
        }
      }

      .hovered {
        color: ${carbonVariables.color12} !important;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        align-items: first baseline;

        img {
          max-width: 12px;
          max-height: 9px;
          margin-left: 9px;
          margin-top: 1px;
          margin-right: 5px;
        }
      }

      #long-text-hovered {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;

        img {
          max-width: 12px;
          max-height: 9px;
          margin-left: 9px;
          margin-top: auto;
          margin-bottom: auto;
          margin-right: 0px;
        }
    }
  }
`;

export const DropBigSize = styled.div`
    z-index: 998;
    color: black;
    width: 100%;
    height: 100%;

    .content-three-columns {
        cursor: default;
        width: 972px;
        height: 452px;
        z-index: 1009;
        display: none;
        background-color: ${carbonVariables.bg08};
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0px;
        left: 1000px;

        @media (max-width: 1170px) {
            top: 0px;
            left: 1450px;
        }

        @media (max-width: 1057px) {
            top: 0px;
            left: 780px;
        }

        @media (min-width: 1057px) and (max-width: 1137px) {
            top: 0px;
            left: 800px;
        }

        @media (min-width: 1137px) and (max-width: 1200px) {
            top: 0px;
            left: 850px;
        }

        @media (min-width: 1200px) and (max-width: 1300px) {
            top: 0px;
            left: 900px;
        }

        @media (min-width: 1300px) and (max-width: 1400px) {
            top: 0px;
            left: 970px;
        }

        opacity: 1;
        border-radius: 0px 0px 8px 8px;

        .separator {
            background-color: ${carbonVariables.bg09};
            height: 400px;
            width: 1px;
            margin: 0 32px 0 25px;
        }

        .banner {
            width: 208px;
            height: 400px;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }

        .text-container {
            min-width: 660px;
            height: 408px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            position: relative;
            top: 10px;
            overflow-y: auto;
            overflow-x: hidden;

            &::-webkit-scrollbar {
                width: 4px;
                background-color: transparent;
                border-radius: 4px;
            }

            &::-webkit-scrollbar-track {
                border-radius: 4px;
                background-color: rgba(0, 0, 0, 0.2);
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 4px;
                background-color: rgba(136, 136, 136);
            }

            &::-webkit-scrollbar-thumb:hover {
                border-radius: 4px;
                background-color: rgba(161, 161, 161);
            }

            .title-grid {
                display: inline-flex;
                width: 232px;
                flex-direction: column;
                text-align: left;

                .arrow-hidden {
                    opacity: 0;
                }
            }

            ul {
                text-align: left;
                width: fit-content;

                margin-bottom: 4px;
                display: inline-flex;
                flex-direction: column;

                .first {
                    margin-top: 5px;
                }

                #long-text {
                    align-items: center;
                }
            }
            .not-hovered {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: first baseline;

                img {
                    opacity: 0;
                    max-width: 12px;
                    max-height: 9px;
                    margin-left: 9px;
                    margin-right: 5px;
                    margin-top: 1px;
                }
            }

            #long-text-hovered {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;

                img {
                    max-width: 12px;
                    max-height: 9px;
                    margin-left: 9px;
                    margin-top: auto;
                    margin-bottom: auto;
                    margin-right: 0px;
                }
            }

            .hovered {
                color: ${carbonVariables.color12} !important;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;

                align-items: first baseline;

                img {
                    max-width: 12px;
                    max-height: 9px;
                    margin-left: 9px;
                    margin-top: 1px;
                    margin-right: 5px;
                }
            }
        }
    }
`;

export const LinkWithIcon = styled.a`
    font: normal normal normal 14px/17px Bradesco Sans;
    letter-spacing: 0.16px;
    color: ${carbonVariables.color04};
    cursor: pointer;
    display: inline;
    box-sizing: border-box;
    word-wrap: break-word;
    width: fit-content;
    word-wrap: break-word;
    white-space: normal;

    :hover {
        color: ${carbonVariables.color12} !important;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
    }

    &:after {
        content: '→';
        opacity: 0;
    }

    &:hover:after {
        opacity: 1;
        transition: transform 500ms;
        transform: translateX(0.5rem);
    }
`;

export const List = styled.ul`
    margin-bottom: 1rem !important;
`;
