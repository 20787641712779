import React from 'react';

export const LogoFacebook = ({ width = '24px', color = 'currentColor' }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 24.101 24">
        <defs>
            <clipPath id="clip-path">
                <rect
                    id="Retângulo_113422"
                    data-name="Retângulo 113422"
                    width={width}
                    height={width}
                />
            </clipPath>
        </defs>
        <g id="Grupo_146062" data-name="Grupo 146062" transform="translate(0 0)">
            <g
                id="Grupo_146036"
                data-name="Grupo 146036"
                transform="translate(0 0)"
                clipPath="url(#clip-path)"
            >
                <path
                    id="Caminho_713386"
                    data-name="Caminho 713386"
                    d="M24.1,11.775v.612c-.024.039-.006.083-.012.124v.241a9.223,9.223,0,0,1-.439,2.81,11.88,11.88,0,0,1-.787,1.96,11.693,11.693,0,0,1-1.558,2.341c-.224.262-.451.522-.7.763a12.22,12.22,0,0,1-4.816,2.946,11.5,11.5,0,0,1-1.618.4.825.825,0,0,1-.138.03.211.211,0,0,1-.113-.05.333.333,0,0,1-.041-.2c0-.361,0-.721,0-1.082q0-3.411,0-6.822c0-.078,0-.157.005-.235a.119.119,0,0,1,.119-.121c.063-.006.125-.006.188-.006h2.27c.22,0,.235-.013.269-.232q.231-1.463.449-2.928c.027-.177,0-.207-.182-.207H14.2c-.319,0-.32,0-.32-.319,0-.674-.005-1.348,0-2.023a2.146,2.146,0,0,1,.244-1.031,1.661,1.661,0,0,1,1.181-.831,2.33,2.33,0,0,1,.5-.052c.447,0,.894,0,1.341,0,.185,0,.211-.027.212-.211q0-1.223,0-2.446c0-.183-.03-.215-.213-.24-.567-.075-1.135-.14-1.706-.17a7.268,7.268,0,0,0-1.93.1,3.853,3.853,0,0,0-3.041,2.791A5.667,5.667,0,0,0,10.254,9c-.047.925-.009,1.85-.022,2.774,0,.067,0,.133,0,.2a.125.125,0,0,1-.134.132c-.063,0-.125,0-.188,0H7.46c-.043,0-.086,0-.129,0-.117.007-.149.041-.155.161,0,.024,0,.047,0,.07q0,1.452,0,2.905c0,.208.021.23.229.23H9.956c.268,0,.276.009.276.279q0,3.969,0,7.939c0,.3-.026.318-.321.264a11.907,11.907,0,0,1-2.7-.825,12.125,12.125,0,0,1-7.1-9.19,9.266,9.266,0,0,1-.094-1.275c0-.138.005-.276,0-.414A.128.128,0,0,1,0,12.22c-.009-.25.019-.5.012-.749A9.773,9.773,0,0,1,.485,8.523,11.672,11.672,0,0,1,1.305,6.5a12.357,12.357,0,0,1,1.53-2.277c.21-.249.43-.489.66-.717A12.125,12.125,0,0,1,7.837.708,11.936,11.936,0,0,1,10.325.074a8.657,8.657,0,0,1,.959-.06c.113,0,.226-.018.34-.012h.886a10.474,10.474,0,0,1,3.759.7,11.932,11.932,0,0,1,2.468,1.253,12.121,12.121,0,0,1,5.169,7.586,8.741,8.741,0,0,1,.183,1.77v.34c.005.042-.013.086.012.127"
                    transform="translate(0 0)"
                    fill={color}
                />
            </g>
        </g>
    </svg>
);

type Props = {
    color?: string;
    width?: string;
};
