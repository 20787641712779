import styled, { css } from 'styled-components';
import carbonVariables from '../../utils/CarbonVars';
import { Card } from '../Card/Card';

type VariantType = {
    variant: 'info' | 'error' | 'warning' | undefined;
};

export const AlertBannerContainer = styled(Card)<VariantType>`
    ${({ variant }) => css`
        max-width: 56rem;
        width: 90vw;

        height: auto;
        padding: 1rem;
        margin-top: 1rem;

        display: flex;
        flex-direction: row;
        gap: 1rem;

        background-color: ${carbonVariables.bg07};

        svg {
            color: ${variant === 'error' ? carbonVariables.colorError : carbonVariables.bg07};
            background-color: ${variant === 'error'
                ? carbonVariables.bg07
                : carbonVariables.colorInfo};
            border-radius: 50%;

            path:last-child {
                color: transparent;
            }
        }

        border-left: 4px solid
            ${variant === 'error' ? carbonVariables.colorError : carbonVariables.colorInfo};

        @media (max-width: 1055px) {
            width: auto;
            max-width: initial;
        }
    `}
`;

export const IconContainer = styled.div`
    .warning {
        path {
            color: ${carbonVariables.color03} !important;
        }
    }
`;
