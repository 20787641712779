import { RadioButton, RadioButtonGroup } from 'carbon-components-react';
import './ButtonRadio.scss';

export const ButtonRadio = ({
    orientation,
    items,
    onClick,
    defaultSelected,
    className,
    name = 'radio-button',
}: Props) => {
    return (
        <RadioButtonGroup
            name={name}
            labelPosition="right"
            orientation={orientation}
            defaultSelected={defaultSelected}
            className={className}
        >
            {items?.map((item) => (
                <RadioButton
                    onClick={onClick}
                    value={item.value}
                    labelText={item.label}
                    key={item.key}
                />
            ))}
        </RadioButtonGroup>
    );
};

type Props = {
    items: { label: string; key: string; value: string }[];
    orientation?: 'horizontal' | 'vertical';
    onClick?: (e?: any) => void;
    defaultSelected?: string;
    className?: string;
    name?: string;
    value?: any;
    onLoad?: any;
};
