import styled from 'styled-components';
import carbonVariables from '../../utils/CarbonVars';

interface CardProps {
    borderVariation?: string;
}

export const Card = styled('div')<CardProps>`
    background-color: ${carbonVariables.color03};
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border-radius: 0.25rem;
    border: 1px solid ${carbonVariables.color07};

    ${({ borderVariation }) =>
        borderVariation === 'shadow' &&
        `box-shadow: 0px 2px 8px ${carbonVariables.color11};
      `};
`;
