import './Error.scss';
import { KcContextBase } from 'keycloakify';
import { Grid, Row } from 'carbon-components-react';
import { LinkExpiredError } from '../../Component/LinkExpiredError/LinkExpiredError';
import { NewHeader } from '../../Component/NewHeader/NewHeader';
import { NewFooter } from '../../Component/NewFooter/NewFooter';

interface Test extends KcContextBase.Error {
    url: {
        loginAction: string;
        resourcesPath: string;
        resourcesCommonPath: string;
        loginRestartFlowUrl: string;
        loginUrl: string;
        loginResetCredentialsUrl?: string;
    };
}

function Error(props: KcContextBase.Error) {
    const newProps: Test = { ...props };

    const errorMsg = { ...props.message, error: null };
    const hasError = false;
    // eslint-disable-next-line prefer-const

    const verifyError = () => {
        switch (props.message.summary) {
            case 'Action expired.':
                return (
                    <LinkExpiredError
                        alt={'O link expirou!'}
                        title={'O link expirou!'}
                        link={'Para receber um novo link clique aqui.'}
                        href={newProps?.url?.loginResetCredentialsUrl}
                    />
                );

            case 'Invalid username or password.':
                return (
                    <LinkExpiredError
                        alt="Se você já tem cadastro, enviamos informações de redefinição de senha para o seu e-mail"
                        title={`Se você já tem cadastro, enviamos informações \n de redefinição de senha para o seu e-mail`}
                        link={'Clique aqui para voltar para ir para o Login'}
                        hasFakeSuccess
                        href={props.url.loginUrl}
                    />
                );

            default:
                return (
                    <LinkExpiredError
                        alt="Ocorreu um erro!"
                        title="Ocorreu um erro!"
                        link={'Clique aqui para voltar para ir para o Login'}
                        href={props.url.loginUrl}
                    />
                );
        }
    };

    return (
        <>
            <NewHeader hrefLogin={props.url.loginUrl} hrefRegister={'#'} />
            <Grid className="pageError">
                <Row>{verifyError()}</Row>
            </Grid>
            <NewFooter />
        </>
    );
}

export default Error;
