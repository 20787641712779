/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { useQuery, gql } from '@apollo/client';

export interface FooterOutputDTO {
    link_loja_google?: string;
    link_loja_apple?: string;
    link_instagram?: string;
    link_facebook?: string;
    link_linkedin?: string;
    link_youtube?: string;
    link_qr_code?: string;
}

export const useNewFooter = () => {
    const QUERY_FOOTER = gql`
        query Footer {
            footer {
                link_loja_google
                link_loja_apple
                link_instagram
                link_facebook
                link_linkedin
                link_youtube
                link_qr_code
            }
        }
    `;

    const getFooter = useQuery<{ footer: FooterOutputDTO }>(QUERY_FOOTER);
    return {
        getFooter: () => {
            return getFooter;
        },
    };
};
