/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { useQuery, gql } from '@apollo/client';

export const useGetHeader = () => {
    const QUERY_HEADER = gql`
        query LogoQuery {
            onboard {
                logo {
                    id
                    logo_e_agro_url
                    logo_e_agro_url_alt
                }
            }
        }
    `;

    const getHeader = useQuery(QUERY_HEADER);
    return {
        getHeader: () => {
            return getHeader;
        },
    };
};
