import { useQuery, gql } from '@apollo/client';

const GET_CATEGORIES_AND_SUBCATEGORIES_QUERY = gql`
    query GetCategoriesAndSubcategories($input: getCategoriesAndSubcategoriesRequest!) {
        getCategoriesAndSubcategories(input: $input) {
            categories {
                items {
                    id
                    name
                    url_key
                    url_path
                    path
                    children {
                        id
                        name
                        url_key
                        url_path
                        path
                        children {
                            id
                            name
                            url_key
                            url_path
                            path
                            children {
                                id
                                name
                                url_key
                                url_path
                                path
                                children {
                                    id
                                    name
                                    url_key
                                    url_path
                                    path
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const useCategories = (
    mainCategory = `${process.env.REACT_APP_MAIN_CATEGORY_MARKETPLACE}`,
) => {
    const { data, error, loading } = useQuery(GET_CATEGORIES_AND_SUBCATEGORIES_QUERY, {
        variables: {
            input: {
                mainCategory: mainCategory,
            },
        },
        fetchPolicy: 'network-only',
    });

    return {
        data,
        error,
        loading,
    };
};
