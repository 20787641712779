import { useEffect, useState } from 'react';
import { CheckmarkFilled16 } from '@carbon/icons-react';
import * as S from './PasswordValidation.styles';

export const PasswordValidation = ({ value, setValidated, className }: PasswordValidationProps) => {
    const [lengthValidation, setLengthValidation] = useState(false);
    const [uppercaseValidation, setUppercaseValidation] = useState(false);
    const [lowercaseValidation, setLowercaseValidation] = useState(false);
    const [numberValidation, setNumberValidation] = useState(false);
    const [specialCharacterValidation, setSpecialCharacterValidation] = useState(false);
    const [isValidated, setIsValidated] = useState(false);

    useEffect(() => {
        if (value) {
            value?.length >= 8 ? setLengthValidation(true) : setLengthValidation(false);
            /[a-z ç]/g.test(value) ? setLowercaseValidation(true) : setLowercaseValidation(false);

            /(.*[A-Z Ç]){2}/g.test(value)
                ? setUppercaseValidation(true)
                : setUppercaseValidation(false);

            /(.*[0-9])/g.test(value) ? setNumberValidation(true) : setNumberValidation(false);
            /[^a-zA-Z 0-9 àáâäãåąčćÇçęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑ]/g.test(
                value,
            )
                ? setSpecialCharacterValidation(true)
                : setSpecialCharacterValidation(false);
        } else {
            setLengthValidation(false);
            setUppercaseValidation(false);
            setLowercaseValidation(false);
            setNumberValidation(false);
            setSpecialCharacterValidation(false);
        }
    }, [value]);

    useEffect(() => {
        lengthValidation &&
        uppercaseValidation &&
        lowercaseValidation &&
        numberValidation &&
        specialCharacterValidation
            ? setIsValidated(true)
            : setIsValidated(false);
    }, [
        lengthValidation,
        uppercaseValidation,
        lowercaseValidation,
        numberValidation,
        specialCharacterValidation,
    ]);

    useEffect(() => {
        setValidated && setValidated(isValidated);
    }, [setValidated, isValidated]);

    return (
        <S.Container className={className} data-testid="password-validation-id">
            <S.Validation done={lengthValidation}>
                <S.IconContainer>
                    <CheckmarkFilled16 />
                </S.IconContainer>
                <span>No mínimo 8 caracteres</span>
            </S.Validation>
            <S.Validation done={uppercaseValidation}>
                <S.IconContainer>
                    <CheckmarkFilled16 />
                </S.IconContainer>
                <span>Ao menos duas letras maiúsculas</span>
            </S.Validation>
            <S.Validation done={lowercaseValidation}>
                <S.IconContainer>
                    <CheckmarkFilled16 />
                </S.IconContainer>
                <span>Ao menos uma letra minúscula</span>
            </S.Validation>
            <S.Validation done={numberValidation}>
                <S.IconContainer>
                    <CheckmarkFilled16 />
                </S.IconContainer>
                <span>Ao menos um número</span>
            </S.Validation>

            <S.Validation done={specialCharacterValidation}>
                <S.IconContainer>
                    <CheckmarkFilled16 />
                </S.IconContainer>
                <span>Ao menos um caractere especial (Ex: !#@$&%*)</span>
            </S.Validation>
        </S.Container>
    );
};

type PasswordValidationProps = {
    value: string | undefined;
    setValidated?: (validated: boolean) => void;
    className?: string;
};

export default PasswordValidation;
