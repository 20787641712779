import { getKcContext } from 'keycloakify';
import ReactDOM from 'react-dom';
import './index.scss';
import Login from './Page/Login/Login';
import Error from './Page/Error/Error';
import Register from './Page/Register/Register';
import ResetPasswordEmailCPF from './Page/ResetPasswordEmailCPF/ResetPasswordEmailCPF';
import LoginUpdateProfile from './Page/LoginUpdateProfile/LoginUpdateProfile';
import LoginUpdatePassword from './Page/LoginUpdatePassword/LoginUpdatePassword';
import UserAuthentication from './Page/UserAuthentication/UserAuthentication';
import UserAuthenticationConfirm from './Page/UserAuthenticationConfirm/UserAuthenticationConfirm';
import { ApolloProvider } from '@apollo/client';
import client from './services/index';
import * as S from './index.styles';
import { TagManagerUtils } from './TagManagerUtils';

const contexto = getKcContext();

const query = window.location.search;
const partes = query.replace('?', '').split('&');
const data: any = {};
TagManagerUtils.initialize(true);

export const getParams = partes.map((dados) => {
    const chaveValor = dados.split('=');
    data[chaveValor[0]] = chaveValor[1];
    return data;
});

ReactDOM.render(
    <S.Page>
        <ApolloProvider client={client}>
            {contexto.kcContext?.pageId === 'login.ftl' ? (
                <Login {...contexto?.kcContext} />
            ) : contexto.kcContext?.pageId === 'register.ftl' ? (
                <Register {...contexto?.kcContext} />
            ) : contexto.kcContext?.pageId === 'login-reset-password.ftl' ? (
                <ResetPasswordEmailCPF {...contexto?.kcContext} />
            ) : contexto.kcContext?.pageId === 'login-update-password.ftl' ? (
                <LoginUpdatePassword {...contexto?.kcContext} />
            ) : contexto.kcContext?.pageId === 'login-update-profile.ftl' ? (
                <LoginUpdateProfile {...contexto?.kcContext} />
            ) : contexto.kcContext?.pageId === 'login-idp-link-confirm.ftl' ? (
                <UserAuthentication {...contexto?.kcContext} />
            ) : contexto.kcContext?.pageId === 'login-otp.ftl' ? (
                <UserAuthenticationConfirm context={contexto?.kcContext} />
            ) : contexto.kcContext?.pageId === 'login-verify-email.ftl' ? (
                <UserAuthenticationConfirm context={contexto?.kcContext} register={true} />
            ) : contexto.kcContext?.pageId === 'error.ftl' ? (
                <Error {...contexto?.kcContext} />
            ) : (
                <>404 - {contexto.kcContext?.pageId}</>
            )}
        </ApolloProvider>
    </S.Page>,
    document.getElementById('root'),
);
