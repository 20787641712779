import { ReactNode } from 'react';
import * as S from './Card.styles';

export const Card = ({ className, children, borderVariation }: Props) => {
    return (
        <S.Card data-testid="card" className={className} borderVariation={borderVariation}>
            {children}
        </S.Card>
    );
};

type Props = {
    className?: string;
    children?: ReactNode;
    borderVariation?: string;
};
