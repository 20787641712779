import React from 'react';
import * as S from './Button.styles';

export const Button = ({
    label,
    type,
    size,
    disabled,
    variant,
    className,
    onClick,
    alt,
    title,
}: Props) => {
    return (
        // @ts-ignore
        <S.ButtonWrapper
            alt={alt}
            title={title}
            className={className}
            type={type}
            variant={variant}
            size={size}
            disabled={disabled}
            onClick={onClick}
            data-testid="button"
        >
            {label}
        </S.ButtonWrapper>
    );
};

type Props = {
    label: any;
    variant: 'primary' | 'secondary';
    size?: string;
    onClick?: (e?: any) => void;
    type?: string;
    disabled?: any;
    className?: string;
    alt: string;
    title: string;
};
