/* eslint-disable @typescript-eslint/no-namespace */
/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/
import TagManager from 'react-gtm-module';
import * as qs from 'query-string';
import { deleteCookie, getCookie } from './utils/cookie';
import { KEY_STORAGE_CHANNEL } from './hook/useChannel';

enum ProfileEnum {
    CLIENTE = 'client',
    ESPECIALISTA = 'specialist',
    ASSESSOR = 'parceiros',
}

export enum ActionType {
    PageView = 'visualizar',
    Click = 'clique',
    Open = 'abrir',
    Close = 'fechar',
    FAQ = 'faq',
    Download = 'download',
    Activate = 'ativar',
    Deactivate = 'desativar',
}

export enum UserLoggedStatus {
    LoggedIn = 'logado',
    LoggedOut = 'deslogado',
}

export enum Event {
    VirtualPageview = 'VirtualPageview',
    EventData = 'Event_Data',
    UserData = 'User_data',
    ScreenData = 'Screen_Data',
}

export type AdditionalField = {
    fieldName: string;
    fieldValue: string;
};

export type EventParameter = {
    userType: string;
    status: string;
    proposalId: string;
};

export type UserProperty = {
    userId: string;
};

export type UtmTags = {
    utmSource?: string;
    utmMedium?: string;
    utmCampaign?: string;
    utmId?: string;
    utmTerm?: string;
    utmContent?: string;
};

export type DLGTM = {
    dlVersion: string;
};
interface IGTM {
    utmCampaign?: string;
    utmContent?: string;
    utmId?: string;
    utmMedium?: string;
    utmSource?: string;
    utmTerm?: string;
}

function removeAccents(str: string) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

function removeSpaceEmpty(str: string) {
    return str.replace(' ', '-');
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace TagManagerUtils {
    let isDebugging = false;
    let gtm: IGTM;
    let masterFirebaseScreenClass;
    export function getTagManagerGtmId() {
        let tagManagerGtmId = '';
        const userAgent = navigator.userAgent;
        const isMobile = userAgent.indexOf('e-Agro-App') >= 0;

        if (isMobile) {
            const isApple = userAgent.indexOf('e-Agro-AppApple') >= 0;
            const isAndroid = userAgent.indexOf('e-Agro-AppAndroid') >= 0;

            if (isApple) {
                tagManagerGtmId = 'GTM-WKLZD7W';
            } else if (isAndroid) {
                tagManagerGtmId = 'GTM-MPSTPKQ';
            }
        } else {
            tagManagerGtmId = 'GTM-WKLZD7W';
        }

        return tagManagerGtmId;
    }

    export function initialize(debug?: boolean) {
        const {
            utm_source: utmSource,
            utm_content: utmContent,
            utm_medium: utmMedium,
            utm_campaign: utmCampaign,
            utm_id: utmId,
            utm_term: utmTerm,
        } = qs.parse(location.search);

        if (utmSource || utmContent || utmMedium || utmCampaign || utmId || utmTerm) {
            gtm = {
                utmSource: utmSource as string,
                utmContent: utmContent as string,
                utmMedium: utmMedium as string,
                utmCampaign: utmCampaign as string,
                utmId: utmId as string,
                utmTerm: utmTerm as string,
            };
        }

        const tagManagerArgs = {
            gtmId: getTagManagerGtmId(),
            id: getTagManagerGtmId(),
        };
        TagManager.initialize(tagManagerArgs);

        try {
            const oldValue = decodeURIComponent(getCookie('utmDataLayerEA') ?? '');
            const valueCookie = oldValue ? JSON.parse(oldValue) : null;
            if (
                valueCookie?.utmSource ||
                valueCookie?.utmContent ||
                valueCookie?.utmMedium ||
                valueCookie?.utmCampaign ||
                valueCookie?.utmId ||
                valueCookie?.utmTerm
            ) {
                gtm = {
                    utmSource: valueCookie.utmSource as string,
                    utmContent: valueCookie.utmContent as string,
                    utmMedium: valueCookie.utmMedium as string,
                    utmCampaign: valueCookie.utmCampaign as string,
                    utmId: valueCookie.utmId as string,
                    utmTerm: valueCookie.utmTerm as string,
                };
                deleteCookie('utmDataLayerEA');
            }
        } catch (error) {
            console.log('err');
        }

        isDebugging = debug || false;
    }

    export function getUserStatus(sessionContext: any) {
        const isUserLogged =
            sessionContext?.username != null && sessionContext?.username?.length > 0;
        const userStatus = isUserLogged ? UserLoggedStatus.LoggedIn : UserLoggedStatus.LoggedOut;

        return userStatus;
    }

    export function getUserId(sessionContext: any) {
        const isUserLogged = getUserStatus(sessionContext) === UserLoggedStatus.LoggedIn;

        if (isUserLogged) {
            return sessionContext?.user?.sub;
        }

        return null;
    }

    export function checkIfAllPropertiesAreNull(utmContent: UtmTags): boolean {
        return (
            utmContent?.utmCampaign == null &&
            utmContent?.utmContent == null &&
            utmContent?.utmId == null &&
            utmContent?.utmMedium == null &&
            utmContent?.utmSource == null &&
            utmContent?.utmTerm == null
        );
    }

    // eslint-disable-next-line no-inner-declarations
    function extractDomain(url: string) {
        try {
            const domain = new URL(url).hostname;
            const domainParts = domain.split('.');
            const domainName = domainParts.slice(-2).join('.');
            return domainName;
        } catch (error) {
            console.error('Erro::', error);
            return null;
        }
    }

    // eslint-disable-next-line no-inner-declarations
    function sendToDayaLayer(
        event: Event,
        eventName: Event,
        actionType: ActionType,
        actionLocation: string,
        loggedInUser: UserLoggedStatus,
        flow: string,
        elementName: string,
        firebaseScreen: string,
        ep?: EventParameter,
        up?: UserProperty,
        dlGtm?: DLGTM,
        additionalFields?: AdditionalField[],
    ) {
        let docTitle = document.title;

        if (window?.location?.pathname?.includes('login-actions/registration')) {
            docTitle = 'Cadastro';
        } else if (
            window?.location?.pathname?.includes('login-actions/auth') ||
            window?.location?.pathname?.includes('openid-connect/auth')
        ) {
            docTitle = 'Login';
        }

        const storagedChannel = sessionStorage.getItem(KEY_STORAGE_CHANNEL);

        if (storagedChannel === ProfileEnum.ASSESSOR) {
            docTitle = 'assessor-eagro';
        }

        const dataLayer = {
            event: event,
            event_name: eventName,
            web_view: {
                url: document.URL,
                hostname: document.location.hostname,
                title: docTitle,
            },
            ep_padrao: {
                firebase_screen: firebaseScreen,
                firebase_screen_class: removeSpaceEmpty(removeAccents(docTitle).toLowerCase()),
                produto: 'e-agro',
                detalhe_produto: '',
                fluxo: flow,
                tipo_acao: actionType,
                local_acao: actionLocation,
                nome_elemento: elementName,
                usuario_logado: loggedInUser,
            },
        };

        if (ep != null) {
            (dataLayer as any).ep = {
                tipo_usuario: ep.userType,
                status: ep.status,
                id_proposta: ep.proposalId,
            };
        }

        if (up != null && up.userId != null) {
            (dataLayer as any).up = {
                user_id: up.userId,
            };
        }

        if (dlGtm != null && dlGtm.dlVersion != null) {
            (dataLayer as any).dl_gtm = {
                versao_dl: dlGtm.dlVersion,
            };
        }

        if (additionalFields != null && additionalFields.length > 0) {
            additionalFields.forEach(
                (field) => ((dataLayer as any)[field.fieldName] = field.fieldValue),
            );
        }

        const utmTags = gtm;
        const areAllTagsNull = checkIfAllPropertiesAreNull(utmTags);
        if (!areAllTagsNull) {
            if ((dataLayer as any).ep == null) {
                (dataLayer as any).ep = {};
            }
            if (utmTags.utmSource) ((dataLayer as any)?.ep as any).utm_source = utmTags.utmSource;
            if (utmTags.utmMedium) ((dataLayer as any)?.ep as any).utm_medium = utmTags.utmMedium;
            if (utmTags.utmCampaign)
                ((dataLayer as any)?.ep as any).utm_campaign = utmTags.utmCampaign;
            if (utmTags.utmContent)
                ((dataLayer as any)?.ep as any).utm_content = utmTags.utmContent;
            if (utmTags.utmTerm) ((dataLayer as any)?.ep as any).utm_term = utmTags.utmTerm;
            if (utmTags.utmId) ((dataLayer as any)?.ep as any).utm_id = utmTags.utmId;
        }

        TagManager.dataLayer({ dataLayer });

        if (isDebugging) {
            console.log(dataLayer, 'Tagueamento - Evento');
            console.log((window as any).dataLayer, 'Tagueamento - DataLayer');
        }
    }

    export function pageview(
        actionType: ActionType,
        flow: string,
        loggedInUser: UserLoggedStatus,
        firebaseScreen: string,
        actionLocation: string,
        ep?: EventParameter,
        up?: UserProperty,
        dlGtm?: DLGTM,
        additionalFields?: AdditionalField[],
        event: Event = Event.ScreenData,
        eventName: Event = Event.ScreenData,
    ) {
        sendToDayaLayer(
            event,
            eventName,
            actionType,
            actionLocation,
            loggedInUser,
            flow,
            '',
            firebaseScreen,
            ep,
            up,
            dlGtm,
            additionalFields,
        );
    }

    export function interact(
        actionType: ActionType,
        actionLocation: string,
        elementName: string,
        flow: string,
        loggedInUser: UserLoggedStatus,
        firebaseScreen: string,
        ep?: EventParameter,
        up?: UserProperty,
        dlGtm?: DLGTM,
        additionalFields?: AdditionalField[],
        event: Event = Event.EventData,
        eventName: Event = Event.EventData,
    ) {
        sendToDayaLayer(
            event,
            eventName,
            actionType,
            actionLocation,
            loggedInUser,
            flow,
            elementName,
            firebaseScreen,
            ep,
            up,
            dlGtm,
            additionalFields,
        );
    }

    export function userdata(sessionContext: any) {
        const userId = getUserId(sessionContext);
        if (userId == null) {
            return;
        }

        const localDataLayer = {
            event: Event.UserData,
            usuario_logado: getUserStatus(sessionContext),
            id_client_ga: 'mockedUserId',
            user_id: userId,
        };

        TagManager.dataLayer({
            dataLayer: localDataLayer,
        });

        if (isDebugging) {
            console.log(localDataLayer, 'Tagueamento - Evento');
            console.log((window as any).dataLayer, 'Tagueamento - DataLayer');
        }
    }
    export function getActionLocation(): string {
        const userAgent = navigator.userAgent;
        const isMobile = userAgent.indexOf('e-Agro-App') >= 0;

        return isMobile ? 'app' : 'web';
    }
    export function overrideFirebaseScreenClass(firebaseScreenClassOverride: string) {
        masterFirebaseScreenClass = firebaseScreenClassOverride;
    }
}
