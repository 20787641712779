import React from 'react';
import * as S from './Text.styles';

export const Text = ({ text, className, color, size, weight }: Props) => {
    return (
        <S.Text data-testid="text" color={color} size={size} weight={weight} className={className}>
            {text}
        </S.Text>
    );
};

type Props = {
    text?: string;
    className?: string;
    color: string;
    size: string;
    weight: string;
};
