import { Modal } from 'carbon-components-react';
import { InputHTMLAttributes, useEffect, useState } from 'react';
import { Checkbox } from '../Checkbox/Checkbox';
import './TermsOfUseModal.scss';

export const TermsOfUseModal = ({ className, termsOfUse, text, onChange, handleTag }: Props) => {
    const [check, setCheck] = useState(false);
    const [open, setOpen] = useState(false);

    const textTerms = text?.split('<splice>');

    const text1 = textTerms ? textTerms[0] : '';
    const text2 = textTerms ? textTerms[1] : '';
    const text3 = textTerms ? textTerms[2] : '';

    const acceptTerms = () => {
        setCheck(true);
        setOpen(false);
        handleTag && handleTag(true);
    };

    const declineTerms = () => {
        setCheck(false);
        setOpen(false);
        handleTag && handleTag(false);
    };

    useEffect(() => {
        onChange && onChange(check);
    }, [check]);

    return (
        <>
            <Checkbox
                labelText={
                    <>
                        {text1}
                        <a onClick={() => setOpen(true)}>{text2}</a>
                        {text3}
                    </>
                }
                name="user.atributes.termsOfUse"
                id={'checkbox'}
                className="checkbox"
                required
                checked={check}
                onChange={() => {
                    if (check) {
                        setCheck(false);
                        handleTag && handleTag(false);
                    } else {
                        setCheck(true);
                        handleTag && handleTag(true);
                    }
                }}
            ></Checkbox>
            <Modal
                primaryButtonText="Voltar"
                secondaryButtonText="Aceito os termos"
                preventCloseOnClickOutside={true}
                open={open}
                onSecondarySubmit={() => acceptTerms()}
                onRequestSubmit={() => declineTerms()}
                className={`button-modal ${className}`}
                data-testid="modal-id"
            >
                <p>{termsOfUse}</p>
            </Modal>
        </>
    );
};

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
    termsOfUse?: string;
    className?: string;
    text?: string;
    onChange?: any;
    handleTag?: any;
}
